<template>
  <div />
</template>

<script>
import turf from "turf"

import store from "@/store"

class MapDrawRectangle {
  constructor(bounds) {
    this.bounds = bounds || []

    this.title = "Delete bounding box"
    this.deleteIconClasses = ["fal", "fa-trash"]
    this.active = false

    this.canvas = null

    // Variable to hold the this.starting xy coordinates
    // when `mousedown` occured.
    this.start = null
    this.end = null
    this.edit = false
    // Variable to hold the this.current xy coordinates
    // when `mousemove` or `mouseup` occurs.
    this.current = null

    // Variable for the draw box element.
    this.box = null

    // Important to do this binding here so that map.off turn it off reliably.
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  insertControls() {
    this.container = document.createElement("div")
    this.container.classList.add("maplibregl-ctrl")
    this.container.classList.add("maplibregl-ctrl-group")
    this.container.title = this.title

    this.button2 = document.createElement("button")
    this.button2.classList.add("maplibregl-ctrl-icon")

    this.icon2 = document.createElement("i")
    this.icon2.classList.add(...this.deleteIconClasses)
    this.button2.classList.add("maplibregl-ctrl-delete")

    this.button2.appendChild(this.icon2)

    this.container.appendChild(this.button2)
  }

  // noinspection JSMethodCanBeStatic
  convertPoint(e) {
    return { pos: e.point, lngLat: e.lngLat }
  }

  dist(p1, p2) {
    p1 = turf.point([p1.lngLat.lng, p1.lngLat.lat])
    p2 = turf.point([p2.lngLat.lng, p2.lngLat.lat])
    // return Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y))
    return turf.distance(p1, p2, "meters")
  }

  isClosePoint(e) {
    let features = []
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ]
    if (this.map?.getLayer("top-left") && this.map?.getLayer("bottom-right"))
      features = this.map.queryRenderedFeatures(bbox, {
        layers: ["top-left", "bottom-right"],
      })
    return features.length > 0
  }

  onMouseDown(e) {
    if (this.isClosePoint(e)) {
      if (
        this.dist(this.start, this.convertPoint(e)) <
        this.dist(this.end, this.convertPoint(e))
      ) {
        let tmp = this.start
        this.start = this.end
        this.end = tmp
      }
      this.map.setLayoutProperty("top-left", "visibility", "none")
      this.map.setLayoutProperty("bottom-right", "visibility", "none")
      this.edit = true
      this.map.getCanvas().style.cursor = "crosshair"
      this.map.dragPan.disable()
      if (!this.edit) return
    }

    if (this.start && !this.end) {
      this.map.getCanvas().style.cursor = "crosshair"
      this.map.dragPan.disable()
      const bbox = [this.start.lngLat, this.convertPoint(e).lngLat]
      this.end = this.convertPoint(e)

      // If bbox exists, fire 'download'
      if (bbox) this.map.fire("drawControl", bbox)
      this.deactivate()
      this.activate()
    } else if (!this.start && !this.end) {
      // Capture the first xy coordinates
      this.start = this.convertPoint(e)
    }
  }

  onMouseUp(e) {
    if (
      (this.start.pos?.x === this.convertPoint(e).pos?.x &&
        this.start.pos?.y === this.convertPoint(e).pos?.y) ||
      !this.edit
    )
      // Ignore if the user just click once
      return
    this.end = this.convertPoint(e)

    const bbox = [this.start.lngLat, this.convertPoint(e).lngLat]
    if (bbox)
      setTimeout(() => {
        this.map.fire("drawControl", bbox)
      }, 1)

    let lat =
      this.start.lngLat.lat > this.convertPoint(e).lngLat.lat
        ? this.start.lngLat.lat
        : this.convertPoint(e).lngLat.lat
    let lng =
      this.start.lngLat.lng < this.convertPoint(e).lngLat.lng
        ? this.start.lngLat.lng
        : this.convertPoint(e).lngLat.lng

    // This seems to not be used actually
    //this.generateLabel(lat, lng)

    this.deactivate()
    this.activate()
  }

  onKeyDown(e) {
    // If the ESC key is pressed
    // if (e.keyCode === 27) this.deactivate()
  }

  onMouseMove(e) {
    if (this.isClosePoint(e)) this.map.getCanvas().style.cursor = "crosshair"
    else this.map.getCanvas().style.cursor = "grab"

    if (!this.start) return

    if (this.start && this.end && !this.edit) return

    //Create polygon geojson string
    let pointA = turf.point([this.start.lngLat.lng, this.start.lngLat.lat])
    let pointB = turf.point([e.lngLat.lng, e.lngLat.lat])
    let bbx = turf.bbox(turf.featureCollection([pointA, pointB]))
    let pgn = turf.bboxPolygon(bbx) //convert it to Polygon feature

    this.generatePolygon(pgn)
  }

  // This seems to not be used.
  /* async generateLabel(lat, lng) {

    if (this.map?.getLayer("label-id")) {
      this.map.removeLayer("label-id")
      this.map.removeSource("label")
    }

    let label = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            label: "Visible data",
          },
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
        },
      ],
    }

    this.map.addSource("label", {
      type: "geojson",
      data: label,
    })

    this.map.addLayer({
      id: "label-id",
      type: "symbol",
      source: "label",
      layout: {
        "text-size": 14,
        "text-font": ["Roboto Regular"],
        "text-field": ["get", "label"],
        "text-anchor": "top",
        "text-offset": [3, -1],
      },
      paint: {
        "text-halo-color": "white",
        "text-halo-width": 0.2,
      },
    })
  }*/

  async generatePolygon(poly_json) {
    if (this.map.getLayer("temp_bbox")) {
      this.map.removeLayer("temp_bbox")
      this.map.removeSource("temp_bbox")
    }
    this.map.addLayer({
      id: "temp_bbox",
      type: "fill",
      source: {
        type: "geojson",
        data: poly_json,
      },
      paint: {
        "fill-color": "#000",
        "fill-opacity": 0.5,
      },
    })
  }

  createMarker(point, name) {
    if (this.map.getLayer(name)) {
      this.map.removeLayer(name)
      this.map.removeSource(name)
    }
    let pointSource = turf.point([point.lng, point.lat])
    this.map.addLayer({
      id: name,
      type: "circle",
      source: {
        type: "geojson",
        data: pointSource,
      },
      layout: {
        visibility: "visible",
      },
      paint: {
        "circle-color": "yellow",
        "circle-radius": 6,
        "circle-stroke-width": 2,
      },
    })

    // let el = document.createElement("div")
    // el.className = "marker"

    // new maplibregl.Marker(el).setLngLat(point).addTo(this.map)
  }

  activate() {
    store.set("controller", "drawControl")
    this.canvas = this.map.getCanvasContainer()

    // this.map.getCanvas().style.cursor = "crosshair"

    this.map.on("mousedown", this.onMouseDown)
    this.map.on("mouseup", this.onMouseUp)

    // Disable default drag zooming when the shift key is held down.
    // this.map.dragPan.disable()

    // Call functions for the following events
    this.map.on("mousemove", this.onMouseMove)
    document.addEventListener("keydown", this.onKeyDown)

    this.active = true
    if (this.start && this.end) {
      this.createMarker(this.start.lngLat, "top-left")
      this.createMarker(this.end.lngLat, "bottom-right")
    }
  }

  deletePolygon() {
    if (this.map?.getLayer("label-id")) {
      this.map.removeLayer("label-id")
      this.map.removeSource("label")
    }

    if (this.map.getLayer("temp_bbox")) {
      this.map.removeLayer("temp_bbox")
      this.map.removeSource("temp_bbox")
    }
    if (this.map.getLayer("top-left")) {
      this.map.removeLayer("top-left")
      this.map.removeSource("top-left")
    }
    if (this.map.getLayer("bottom-right")) {
      this.map.removeLayer("bottom-right")
      this.map.removeSource("bottom-right")
    }
    this.start = null
    this.end = null
    this.map.fire("drawControl", [])
    this.activate()
  }

  deactivate() {
    store.set("controller", "")
    this.canvas = null

    this.map.getCanvas().style.cursor = ""

    // Remove these events now that finish has been called.
    this.map.off("mousedown", this.onMouseDown)
    this.map.off("mouseup", this.onMouseUp)
    this.map.off("mousemove", this.onMouseMove)

    document.removeEventListener("keydown", this.onKeyDown)

    this.map.dragPan.enable()

    // this.start = null
    this.active = false
    this.edit = false
  }

  onAdd(map) {
    this.map = map
    this.insertControls()
    /*this.button.addEventListener("click", () =>
      this.active ? this.deactivate() : this.activate()
    )*/

    this.button2.addEventListener("click", () => this.deletePolygon())

    if (this.bounds.length > 0) {
      this.start = {
        lngLat: {
          lat: this.bounds[1],
          lng: this.bounds[0],
        },
      }
      this.end = {
        lngLat: {
          lat: this.bounds[3],
          lng: this.bounds[2],
        },
      }
      // this.map.fitBounds(this.bounds, { animate: false, padding: 20 })
      let pgn = turf.bboxPolygon(this.bounds) //convert it to Polygon feature

      this.generatePolygon(pgn)
      // this.activate()
    }
    this.activate()

    return this.container
  }

  onRemove() {
    this.container?.parentNode.removeChild(this.container)
    // this.map = undefined
  }
}

// Component for downloading from map
export default {
  name: "MapDrawRectangle",

  props: {
    value: { type: Array, required: true },
    maplibre: { type: Object, required: true },
    edit: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
      download: {
        title: "",
        show: false,
        data: [],
      },
      bounds: null,
      drawControl: null,
    }
  },

  computed: {
    controller() {
      return store.state.controller
    },
  },

  watch: {
    // watch for active controller change
    controller(current, previous) {
      if (previous === "drawControl") this.cancelEvent()
    },

    value(value, oldValue) {
      this.maplibre.removeControl(this.drawControl)
      this.drawControl = new MapDrawRectangle(this.bounds)
      this.maplibre.addControl(this.drawControl, "bottom-right")
    },

    edit(value, oldValue) {
      if (value) this.maplibre.addControl(this.drawControl, "bottom-right")
      else {
        this.maplibre.removeControl(this.drawControl)

        if (this.maplibre?.getLayer("top-left")) {
          this.maplibre.setLayoutProperty("top-left", "visibility", "none")
          this.maplibre.setLayoutProperty("bottom-right", "visibility", "none")
        }
      }
    },
  },

  created() {
    this.bounds = this.value || []
    // Add controller when component created
    this.drawControl = new MapDrawRectangle(this.bounds)
    // this.maplibre.addControl(this.drawControl)
    this.maplibre.on("drawControl", this.getData)
  },

  methods: {
    // @vuese
    // Get data from backend
    // @arg coordinates as array
    async getData(coords) {
      let bounds = []
      if (coords[0] && coords[1]) {
        bounds.push(coords[0].lng)
        bounds.push(coords[0].lat)
        bounds.push(coords[1].lng)
        bounds.push(coords[1].lat)
      }
      this.bounds = bounds
      this.$emit("input", bounds)
    },

    cancelEvent() {
      this.drawControl.deactivate()
    },
  },
}
</script>

<style>
.maplibregl-marker {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}
.marker {
  background-color: #00c853;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.maplibregl-ctrl-delete i {
  font-size: 18px;
}
</style>
