<template>
  <v-app>
    <div v-if="initializing" class="d-flex justify-center align-center fill-height">
      <div class="mt-6">Initialising...</div>
    </div>

    <div v-else-if="layout" style="display: contents">
      <!-- Load router-view with sidebar and header components -->
      <toast />

      <v-app-bar
        v-if="showBars"
        :color="layout.navbarColor"
        app
        clipped-left
        clipped-right
        fixed
      >
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.smAndDown || !drawer"
          app
          :style="`color: ${(layout && layout.navbarColorText) || 'inherit'}`"
          @click.stop="drawer = !drawer"
        />

        <v-toolbar-title class="ml-0 pl-0 d-flex align-center">
          <v-img
            v-if="layout.logo"
            :src="layout.logo"
            max-height="55"
            width="150"
            contain
          />

          <!--  eslint-disable vue/no-v-html -->
          <span
            v-if="layout.title"
            :style="`color: ${(layout && layout.navbarColorText) || 'inherit'}`"
            class="ml-2 org-title"
            v-html="layout.title"
          />
          <!--    Skipped    -->
          <v-divider class="ml-5" vertical />
        </v-toolbar-title>

        <v-toolbar-title
          :style="`color: ${(layout && layout.navbarColorText) || 'inherit'}`"
          class="headline ml-5"
        >
          <span v-if="$route.meta.module === 'admin'"> Settings </span>
          <span
            v-else-if="mapShare && mapShareData && map && $route.meta.module === 'maps'"
          >
            {{ map.title }}
          </span>
          <switch-map v-else-if="$route.meta.module === 'maps'" />
        </v-toolbar-title>

        <v-spacer />

        <main-navigation />

        <user-menu v-if="!mapShare" />
      </v-app-bar>

      <v-main>
        <sidebar v-if="showBars" />
        <transition :name="routeTransition">
          <router-view />
        </transition>
      </v-main>
    </div>
    <div v-else class="d-flex justify-center align-center fill-height">
      <v-alert type="error" class="mt-6">
        <span>This domain is not configured. Please contact the admin.</span>
      </v-alert>
    </div>
  </v-app>
</template>

<script>
import { get, sync } from "vuex-pathify"
import MainNavigation from "@/modules/core/MainNavigation"
import SwitchMap from "@/components/SwitchMap"
import Toast from "@/components/Toast"
import UserMenu from "@/modules/users/UserMenu"
import Sidebar from "@/modules/core/Sidebar"

// @vuese
// Default template
// @group Layouts
export default {
  name: "App",

  components: {
    Sidebar,
    UserMenu,
    MainNavigation,
    SwitchMap,
    Toast,
  },

  data: () => ({
    initializing: true,
    routeTransition: null,
  }),

  computed: {
    currentOrganisation: get("currentOrganisation"),
    layout: get("layout"),
    lang: sync("lang"),
    drawer: sync("drawer"),
    user: sync("auth/user"),
    ...get("maps", ["maps", "mapsById"]),

    map() {
      return this.mapsById[this.mapShareData.map] || null
    },

    showBars() {
      return !["Login", "PasswordReset", "PasswordResetConfirm"].includes(
        this.$route.name
      )
    },

    //@vuese
    // get title from store
    title() {
      return this.$store.get("title") || this.localStore.getItem("title")
    },

    // compute development environment
    dev() {
      return process.env.NODE_ENV === "development"
    },

    // Compute logged-in status
    // Compute map share status
    mapShare() {
      return !!this.$store.get("auth/mapShare")
    },

    mapShareData() {
      return this.$store.get("maps/mapShareData")
    },
  },

  watch: {
    currentOrganisation(value) {
      if (!value) return

      // TODO: instead of immediately redirecting the user show a prompt and ask ?
      let currentHost = location.hostname
      if (
        this.currentOrganisation?.domain !== currentHost &&
        this.currentOrganisation?.alt_domains.indexOf(currentHost) === -1
      ) {
        const protocol = window.location.protocol
        const port = window.location.port
        window.location = `${protocol}//${this.currentOrganisation.domain}${
          port ? ":" + port : ""
        }?orgId=${this.currentOrganisation.id}`
      }
    },

    $route(to, from) {
      const toDepth = to.path.split("/").length
      const fromDepth = from.path.split("/").length
      if (toDepth === fromDepth) this.routeTransition = "fade-transition"
      else
        this.routeTransition =
          toDepth < fromDepth ? "slide-x-reverse-transition" : "slide-x-transition"
    },

    maps(value) {
      if (value.length > 0) this.initializing = false
    },
  },

  async created() {
    await this.$store.dispatch("determineHostLayout")
    if (!this.user?.id) this.initializing = false
    await this.$store.dispatch("auth/loadTokenFromCache")
    await this.$store.dispatch("loadImpersonateActive")
  },
}
</script>
