<template>
  <div class="json-editor fill-height">
    <label :class="!edit ? 'fake-label ml-2' : 'label'"> {{ label }}</label>
    <pre v-if="edit" class="pt-3 black--text fill-height"> {{ json }} </pre>

    <v-jsoneditor v-else v-model="json" :options="options" @error="onError" />

    <div v-if="persistentHint" class="v-text-field__details mt-2 ml-2">
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper">
          <div class="d-flex align-center v-messages__message">
            {{ hint }}

            <v-spacer />

            <v-menu v-if="!edit" offset-y>
              <template #activator="{ on, attrs }">
                <v-btn color="primary" outlined x-small v-bind="attrs" v-on="on">
                  Apply {{ label }} template
                  <v-icon class="ml-1" x-small>fal fa-angle-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in templates[label]"
                  :key="index"
                  @click="applyTemplate(item.jsonTemplate)"
                >
                  <v-list-item-title> {{ item.name }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor/src/index.js"

import autocadStyle from "@/modules/layers/templates/autocad-style.json"
import basicStyle from "@/modules/layers/templates/basic-style.json"
import autocadLabel from "@/modules/layers/templates/autocad-label.json"

export default {
  name: "JsonEditor",

  components: {
    VJsoneditor,
  },

  props: {
    value: { type: [Object, Array], default: () => {} },
    edit: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    label: { type: String, default: "" },
    hint: { type: String, default: "" },
  },

  data: () => ({
    json: {},
    templates: {
      Style: [
        { name: "Basic", jsonTemplate: basicStyle },
        { name: "AutoCAD Default", jsonTemplate: autocadStyle },
      ],
      Label: [{ name: "AutoCAD Default", jsonTemplate: autocadLabel }],
    },
  }),

  computed: {
    options() {
      return {
        mode: "code",
        search: false,
        history: false,
        statusBar: false,
        mainMenuBar: false,
        navigationBar: false,
        onChangeText: (jsonString) => {
          try {
            this.$emit("input", JSON.parse(jsonString))
          } catch (e) {}
        },
      }
    },
  },

  watch: {
    value(newVal) {
      this.json = newVal
    },
  },

  created() {
    this.json = this.value
  },

  methods: {
    onError(err) {
      console.error(err)
    },

    applyTemplate(jsonTemplate) {
      this.$emit("input", jsonTemplate)
    },
  },
}
</script>

<style scoped>
.json-editor >>> .ace_scrollbar {
  z-index: 1 !important;
}
.fake-label {
  position: absolute;
  margin-left: 15px;
  margin-top: -10px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  z-index: 1;
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}
.label {
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}
.json-editor >>> .jsoneditor {
  min-height: 50px;
  padding: 18px;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
.jsoneditor-container.min-box {
  min-width: 200px;
}
pre {
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  padding-left: 6px;
}
</style>
