var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.fieldStatic || _vm.readonly || _vm.disabled)?_c('text-field-static',{class:{ 'body-2': _vm.smallText },attrs:{"dark":_vm.dark,"empty":_vm.empty,"label":_vm.label,"light":_vm.light,"multi-line":_vm.multiLine,"outlined":_vm.outlined,"value":_vm.value}},[_vm._t("default",function(){return [(_vm.empty && _vm.empty.length > 0 && (!_vm.value || _vm.value.length === 0))?_c('span',{domProps:{"innerHTML":_vm._s(_vm.empty)}}):(_vm.multiLine)?_c('span',{class:{ 'body-2': _vm.smallText },staticStyle:{"max-height":"15rem","overflow-y":"auto"},domProps:{"innerHTML":_vm._s(_vm.valueHtml)}}):_c('span',[_vm._v(_vm._s(_vm.value))])]})],2):_vm._e(),(!_vm.fieldStatic && !_vm.readonly && !_vm.disabled && !_vm.multiLine)?_c('v-text-field',_vm._g(_vm._b({class:{ 'body-2': _vm.smallText },attrs:{"label":_vm.required ? _vm.label + '*' : _vm.label,"rules":_vm.localRules,"readonly":_vm.timeField ? _vm.timeField : _vm.readonly},on:{"input":_vm.input,"click:append":_vm.clickAppend}},'v-text-field',{
      appendIcon: _vm.appendIcon,
      autoGrow: _vm.autoGrow,
      color: _vm.color,
      dark: _vm.dark,
      dense: _vm.dense,
      filled: _vm.filled,
      hideDetails: _vm.hideDetails,
      errorMessages: _vm.errorMessages,
      hint: _vm.hint,
      light: _vm.light,
      multiLine: _vm.multiLine,
      name: _vm.name,
      outlined: _vm.outlined,
      persistentHint: _vm.persistentHint,
      prefix: _vm.prefix,
      prependIcon: _vm.prependIcon,
      required: _vm.required,
      rows: _vm.rows,
      solo: _vm.solo,
      suffix: _vm.suffix,
      textarea: _vm.textarea,
      type: _vm.type,
      validateOnBlur: _vm.validateOnBlur,
      value: _vm.value,
      flat: _vm.flat,
    },false),_vm.activator)):(!_vm.fieldStatic && !_vm.readonly && !_vm.disabled && _vm.multiLine)?_c('v-textarea',_vm._g(_vm._b({class:{ 'body-2': _vm.smallText },attrs:{"label":_vm.required ? _vm.label + '*' : _vm.label,"rules":_vm.localRules},on:{"input":_vm.input}},'v-textarea',{
      appendIcon: _vm.appendIcon,
      autoGrow: _vm.autoGrow,
      color: _vm.color,
      dark: _vm.dark,
      dense: _vm.dense,
      filled: _vm.filled,
      hideDetails: _vm.hideDetails,
      hint: _vm.hint,
      light: _vm.light,
      multiLine: _vm.multiLine,
      name: _vm.name,
      outlined: _vm.outlined,
      readonly: _vm.readonly,
      persistentHint: _vm.persistentHint,
      prefix: _vm.prefix,
      required: _vm.required,
      rows: _vm.rows,
      solo: _vm.solo,
      suffix: _vm.suffix,
      textarea: _vm.textarea,
      type: _vm.type,
      validateOnBlur: _vm.validateOnBlur,
      value: _vm.value,
    },false),_vm.activator)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }