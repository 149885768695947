<template>
  <v-card>
    <v-card-title class="primary white--text">
      How to upload files

      <v-spacer />

      <v-btn dark icon @click="$emit('input', false)">
        <v-icon>fal fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mt-2 body-1">
      <p>There are 2 fundamental types of data that can be uploaded:</p>

      <ul class="mt-2">
        <li>
          <span class="title font-weight-bold">Geospatial Data</span>
          <p>This is data that should be displayed on the map.</p>
          <p>
            Any geospatial data that is uploaded <b>must</b> be in a national or
            international coordinate system (OSGB, ITM, UTM, etc) and the type of
            coordinate system must be set in the file itself (GeoTIFF, GeoPackage) or in
            an accompanying PRJ file.
          </p>

          Currently the Portal supports the following geospatial file formats:
          <ul class="mt-2">
            <li>
              <i><b>Raster files</b></i> (aerial photos, scanned maps, etc)
              <ul>
                <li>GeoTIFF, TIFF+PRJ</li>
                <li>JPG+PRJ</li>
                <li>PNG+PRJ</li>
              </ul>
            </li>
            <li class="mt-2">
              <i><b>Vector files</b></i> (drawings)
              <ul>
                <li>DWG+PRJ</li>
                <li>DXF+PRJ</li>
                <li>SHP+PRJ</li>
                <li>GeoPackage files</li>
              </ul>
            </li>
            <li class="mt-2">
              <i><b>360deg Photos</b></i>
              <ul>
                <li>JPG with geolocation info in EXIF data</li>
              </ul>
            </li>
            <li class="mt-2">
              <i><b>Zip files</b></i>
              <p>
                Zip files can be used to upload a large amount of data in one go. The
                above file formats can be used but there must only be either raster or
                vector files in the zip; not a mix.
              </p>
              <p>
                <b>Important:</b> directories in the file are treated as attachments
                (see below) and not as geospatial data.
              </p>
            </li>
          </ul>
        </li>

        <li class="mt-3">
          <span class="title font-weight-bold">Attachments Data</span>
          <p>
            This is data that is linked to geospatial features on the same layer.
            Therefore it requires that the layer has geospatial data with a field which
            will be used to link to the attachments in directories.
          </p>

          <p>
            Attachments must be uploaded in a <b>zip file</b> which contains the data in
            subdirectories.
          </p>
          <p class="text-h6">Example:</p>

          <ol>
            <li>
              <b>Geospatial data</b>, e.g. a shapefile with a field "report_id", has
              been uploaded:

              <v-simple-table class="ml-6">
                <template #default>
                  <thead>
                    <tr>
                      <th>FID</th>
                      <th>report_id</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>REP001</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>REP002</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>REP003-20200919</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </li>
            <li>
              <p>
                <b>Attachments data</b> has been uploaded as a zip file with the
                following file structure in the zip file:
              </p>
              <ul>
                <li>
                  REP001
                  <ul>
                    <li>Inspection_20200118.pdf</li>
                    <li>point_cloud_REP001.las</li>
                  </ul>
                </li>
                <li class="mt-2">
                  REP002
                  <ul>
                    <li>Certificate.jpg</li>
                    <li>point_cloud.e57</li>
                    <li>
                      Inspections 2019
                      <ul>
                        <li>Report 2019.pdf</li>
                        <li>Report 2019.docx</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="mt-2">
                  REP003-20200919
                  <ul>
                    <li>Photo_0001.jpg</li>
                    <li>Photo_0002.jpg</li>
                    <li>site_plan.dwg</li>
                    <ul>
                      2019
                      <ul>
                        <li>site_plan_old.dwg</li>
                        <li>fault.jpg</li>
                      </ul>
                    </ul>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              The <b>Map</b> will display the points from the geospatial data and if
              somebody clicks on that point, then a popup will show the attachment data
              and allow the user to view or download.
            </li>
          </ol>
        </li>
      </ul>
      <p>
        The front page upload allows adding data quickly and creating maps quickly. To
        configure more features, such as what map controls to show, how to style the
        layer or how to show popups, go into the administration area.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FilesInformation",

  props: {
    value: { type: Boolean, default: null },
  },
}
</script>
