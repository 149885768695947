<template>
  <div>
    <v-card
      @dragover.native.prevent="dragover = true"
      @dragenter.native.prevent="dragover = true"
      @dragleave.native.prevent="dragover = false"
      @dragend.native.prevent="drop"
      @drop.native.prevent="drop"
    >
      <v-card-title
        class="primary white--text"
        style="position: sticky; top: 0; z-index: 3"
      >
        Upload files

        <v-spacer />

        <v-btn :disabled="uploading" dark icon @click="close">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-row class="ma-1">
          <v-select
            ref="selectmap"
            v-model="map"
            :loading="loading"
            :items="maps"
            :disabled="uploading"
            hint="Select an existing map or Create new map"
            label="Map"
            item-text="name"
            item-value="id"
            persistent-hint
            return-object
            outlined
            dense
          >
            <template v-if="canAddMap" #prepend-item>
              <v-col>
                <v-btn
                  id="add-map"
                  color="primary"
                  dark
                  block
                  small
                  @click="showMapAdd = true"
                >
                  Create Map
                  <v-icon small class="ml-2">fal fa-plus</v-icon>
                </v-btn>
                <map-add
                  v-model="showMapAdd"
                  :show-btn="false"
                  @done="mapCreated"
                  @error="showToast"
                />
              </v-col>
            </template>
          </v-select>
        </v-row>
      </v-card-text>

      <v-expand-transition>
        <v-card-text v-show="!loading" class="mt-0 pt-0">
          <input
            id="fileUpload"
            ref="upload"
            type="file"
            :disabled="uploading"
            style="display: none"
            multiple
            @input="select"
          />
          <v-sheet
            id="dropzone"
            ref="dzone"
            :color="dragover ? 'primary lighten-3' : null"
            tabindex="0"
            :disabled="uploading"
            width="100%"
            class="pa-2"
            style="cursor: pointer; border: 2px dashed grey"
            @dragover.native.prevent="dragover = true"
            @dragenter.native.prevent="dragover = true"
            @dragleave.native.prevent="dragover = false"
            @dragend.native.prevent="drop"
            @drop.native.prevent="drop"
            @click.native.prevent="click"
            @change.native.prevent="change"
          >
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" class="py-2 d-flex justify-center align-center">
                  <v-icon v-if="dragover" color="secondary"> fal fa-layer-plus</v-icon>
                  <v-icon v-else color="secondary"> fal fa-cloud-upload</v-icon>

                  <span
                    v-if="dragover"
                    style="min-height: 50px"
                    class="ml-3 pt-3 title secondary--text"
                  >
                    Add files
                  </span>
                  <span
                    v-else
                    style="min-height: 50px"
                    class="ml-3 text-body-1 secondary--text"
                  >
                    Drag &amp; drop files here <br />or click to select files to upload
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

          <v-row>
            <v-col cols="12" class="text-center pb-0 mb-0">
              <v-dialog v-model="dialogFormats" width="50%" scrollable>
                <template #activator="{ on, attrs }">
                  <small class="primary--text text-center" v-bind="attrs" v-on="on">
                    <v-icon color="primary" x-small> fal fa-question-circle</v-icon>
                    More information about file formats
                  </small>
                </template>

                <files-information v-model="dialogFormats" />
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
      </v-expand-transition>

      <v-expand-transition>
        <v-card-text v-if="fileList.length > 0" class="mt-0 pt-0">
          <v-row>
            <v-col cols="12" class="mt-0 pt-0">
              <v-simple-table
                :height="fileList.length < 8 ? null : 420"
                class="pt-0 mt-0"
                fixed-header
              >
                <template #default>
                  <thead>
                    <tr>
                      <th class="subtitle-2 black--text">
                        <v-checkbox
                          v-model="batch"
                          dense
                          class="mt-4"
                          @change="selectAllToBatch"
                        />
                      </th>

                      <th class="subtitle-2 black--text">Name</th>

                      <th class="subtitle-2 black--text text-end">Size</th>

                      <th class="subtitle-2 black--text">Type</th>

                      <th class="subtitle-2 black--text">
                        Layer

                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              class="ml-1"
                              color="info"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              fal fa-question-circle
                            </v-icon>
                          </template>
                          <span>
                            <span v-if="canAddLayer">
                              Enter the name for a new layer or choose an existing layer
                              of the map
                            </span>
                            <span v-else> Choose an existing layer of the map </span>
                          </span>
                        </v-tooltip>
                      </th>
                      <th />
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item, index) in fileList"
                      :key="`${item.name}-${index}`"
                    >
                      <td>
                        <v-checkbox
                          v-model="item.batch"
                          :disabled="!!item.parent"
                          class="ck-item mt-4"
                          dense
                          @change="addToBatch(item), updateChildren(item)"
                        />
                      </td>

                      <td>
                        <v-icon v-if="item.parent" class="ml-2 mr-1" small>
                          fal fa-level-up fa-rotate-90
                        </v-icon>
                        {{ item.name }}
                      </td>

                      <td class="text-end text-no-wrap">
                        {{ formatFilesize(item.size) }}
                      </td>

                      <td class="text-center">
                        <span v-if="item.name.split('.').pop() === 'zip'">
                          Auto
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="primary">
                                  fal fa-question-circle
                                </v-icon>
                              </v-btn>
                            </template>
                            <span> Detect file type by processor </span>
                          </v-tooltip>
                        </span>

                        <v-select
                          v-else-if="
                            getAvailableFileTypes(item).length > 0 && !item.parent
                          "
                          v-model="item.fileType"
                          :items="getAvailableFileTypes(item)"
                          label="File Type"
                          :disabled="uploading || batchDisabled(item, index)"
                          hide-details
                          solo
                          dense
                          @change="validateFile(item)"
                        />

                        <span v-else-if="item.fileType">{{ item.fileType }}</span>

                        <span v-else>&ndash;</span>
                      </td>

                      <td class="text-center">
                        <span>
                          <span v-if="item.parent && item.layer">
                            {{ getLayerName(item.layer) }}
                          </span>
                          <span v-else-if="item.parent">&ndash;</span>

                          <v-select
                            v-if="!item.parent"
                            ref="select"
                            v-model="item.layer"
                            :items="availableLayers(item)"
                            :label="
                              availableLayers(item).length > 0
                                ? 'New or existing layer'
                                : 'New layer'
                            "
                            :disabled="uploading || batchDisabled(item, index)"
                            item-text="name"
                            item-value="id"
                            hide-details
                            clearable
                            solo
                            dense
                            required
                            @change="
                              validateFile(item), setLayerFieldValues(item.layer)
                            "
                          >
                            <template v-if="canAddLayer" #prepend-item>
                              <v-col>
                                <v-btn
                                  id="add-layer"
                                  color="primary"
                                  dark
                                  block
                                  small
                                  @click="showLayerAdd = true"
                                >
                                  Create Layer
                                  <v-icon small class="ml-2">fal fa-layer-plus</v-icon>
                                </v-btn>
                                <layer-add
                                  v-model="showLayerAdd"
                                  :file="item"
                                  @done="layerCreated"
                                />
                              </v-col>
                            </template>

                            <template #item="{ item: layer }">
                              <v-chip
                                v-if="!layer.id"
                                :color="typeColour('new').background"
                                :dark="typeColour('new').dark"
                                class="mr-1 px-2"
                                label
                                small
                              >
                                NEW
                              </v-chip>

                              <v-chip
                                :color="typeColour(layer.type).background"
                                :dark="typeColour(layer.type).dark"
                                class="mr-1 px-2"
                                label
                                small
                              >
                                {{ layer.type }}
                              </v-chip>
                              {{ layer.name }}
                            </template>
                          </v-select>
                        </span>
                      </td>

                      <td class="text-center">
                        <v-combobox
                          v-if="
                            !item.parent &&
                            item.fileType &&
                            item.fileType !== 'zip' &&
                            item.fileType !== 'attachment' &&
                            !batchDisabled(item, index) &&
                            item.epsgRequired != null &&
                            !item.epsg
                          "
                          v-model="item.epsg"
                          :items="availableEpsg"
                          :search-input="search"
                          :return-object="true"
                          :disabled="uploading"
                          :label="
                            item.epsgRequired
                              ? 'Select a standard coordinate system or enter a EPSG code'
                              : getProjectionMessage(item)
                          "
                          item-value="id"
                          item-text="text"
                          hide-selected
                          solo
                          dense
                          clearable
                          hide-details
                          @update:search-input="searchInput"
                          @change="updateChildren(item)"
                        >
                          <template #no-data>
                            <v-list-item @click.stop="addItem(searchValue)">
                              <span class="subheading">Create</span>
                              <v-chip label small>
                                {{ searchValue }}
                              </v-chip>
                            </v-list-item>
                          </template>
                          <template v-if="!item.epsgRequired" #append-item>
                            <v-list-item ripple @click="updateChildrenPrj(item)">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <strong>{{ getProjectionMessage(item) }}</strong>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <!--  eslint-disable-next-line vue/no-template-shadow -->
                          <template #selection="{ item }">
                            {{ item.text }}
                            <span v-if="item.id"> ({{ item.id }}) </span>
                          </template>

                          <template #append>
                            <v-tooltip max-width="400" bottom>
                              <template #activator="{ on, attrs }">
                                <v-icon
                                  class="ml-1"
                                  color="info"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  fal fa-question-circle
                                </v-icon>
                              </template>
                              <span>
                                <p>
                                  Any geospatial file that should be displayed on the
                                  map needs a coordinate system definition, so that the
                                  Portal knows how to transform it.
                                </p>
                                <p>
                                  The Portal is using the internationally recognised
                                  EPSG coding system for coordinate systems, projections
                                  and transformations.
                                </p>
                                See https://epsg.io
                              </span>
                            </v-tooltip>
                          </template>
                        </v-combobox>

                        <span
                          v-else-if="
                            batchDisabled(item, index) &&
                            item.epsg &&
                            item.fileType !== 'attachment'
                          "
                        >
                          {{ item.epsg.text }}
                        </span>

                        <template v-else-if="item.fileType === 'attachment'">
                          <!--  eslint-disable-next-line vue/no-parsing-error -->
                          <v-autocomplete
                            v-if="!item.parent && item.layer"
                            v-model="item.attachWhere"
                            :items="getAttachmentOptions(item.layer)"
                            :disabled="uploading || batchDisabled(item, index)"
                            label="Attach to feature:"
                            solo
                            dense
                            clearable
                            hide-details
                            @change="updateChildren(item)"
                          >
                            <template #append>
                              <v-tooltip max-width="400" bottom>
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    class="ml-1"
                                    color="info"
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    fal fa-question-circle
                                  </v-icon>
                                </template>
                                <span>
                                  <p>Attach to Feature</p>
                                </span>
                              </v-tooltip>
                            </template>
                          </v-autocomplete>

                          <!-- <span v-else-if="item.attachWhere">
                            {{ item.attachWhere }}
                          </span> -->
                        </template>

                        <template v-else>
                          <p
                            v-if="
                              item.epsg &&
                              item.fileType &&
                              item.fileType !== 'zip' &&
                              item.fileType !== 'attachment'
                            "
                          >
                            {{ item.epsg.text }}
                            <span v-if="item.epsg.id"> ({{ item.epsg.id }}) </span>
                          </p>

                          <p v-else class="mt-4">&ndash;</p>
                        </template>
                      </td>

                      <td>
                        <v-btn
                          icon
                          small
                          :disabled="uploading"
                          @click="remove(item.name)"
                        >
                          <v-icon small>fal fa-times-circle</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-dialog transition="dialog-bottom-transition" max-width="750">
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="batchFiles.length === 0"
                :color="batchFiles.length > 0 ? 'primary' : ''"
                class="mt-2"
                v-bind="attrs"
                v-on="on"
              >
                Set
              </v-btn>
            </template>

            <template #default="dialog">
              <v-card>
                <v-toolbar class="title" color="primary" dark> Batch Edit</v-toolbar>

                <v-card-text>
                  <v-row class="mt-2">
                    <v-col>
                      <v-select
                        v-model="batchType"
                        :items="batchFileTypes"
                        label="Select file type"
                        dense
                        outlined
                        hide-details
                        @change="batchApply"
                        @click="batchApply"
                      />
                    </v-col>

                    <v-col>
                      <v-select
                        v-model="batchLayer"
                        :items="batchLayers"
                        item-text="name"
                        item-value="id"
                        label="Select layer"
                        dense
                        outlined
                        hide-details
                        @change="batchApply, setLayerFieldValues(batchLayer)"
                      />
                    </v-col>

                    <v-col>
                      <v-combobox
                        v-if="
                          batchType && batchType !== 'zip' && batchType !== 'attachment'
                        "
                        v-model="batchEpsg"
                        :items="availableEpsg"
                        :search-input="search"
                        :return-object="true"
                        :disabled="uploading"
                        :label="
                          batchEpsgRequired
                            ? 'Select a standard coordinate system or enter a EPSG code'
                            : getBatchProjectionMessage()
                        "
                        item-value="id"
                        item-text="text"
                        hide-selected
                        outlined
                        solo
                        dense
                        clearable
                        hide-details
                        @change="batchApply"
                      >
                        <template #no-data>
                          <v-list-item @click.stop="addItem(searchValue)">
                            <span class="subheading">Create</span>
                            <v-chip label small>
                              {{ searchValue }}
                            </v-chip>
                          </v-list-item>
                        </template>
                        <template v-if="!batchEpsgRequired" #append-item>
                          <v-list-item ripple @click="batchApply()">
                            <v-list-item-content>
                              <v-list-item-title>
                                <strong>{{ getBatchProjectionMessage() }}</strong>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <!--  eslint-disable-next-line vue/no-template-shadow -->
                        <template #selection="{ item }">
                          {{ item.text }}
                          <span v-if="item.id"> ({{ item.id }}) </span>
                        </template>

                        <template #append>
                          <v-tooltip max-width="400" bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-1"
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-question-circle
                              </v-icon>
                            </template>
                            <span>
                              <p>
                                Any geospatial file that should be displayed on the map
                                needs a coordinate system definition, so that the Portal
                                knows how to transform it.
                              </p>
                              <p>
                                The Portal is using the internationally recognised EPSG
                                coding system for coordinate systems, projections and
                                transformations.
                              </p>
                              See https://epsg.io
                            </span>
                          </v-tooltip>
                        </template>
                      </v-combobox>

                      <v-autocomplete
                        v-if="batchType === 'attachment'"
                        v-model="batchAttachWhere"
                        :items="getAttachmentOptions(batchLayer)"
                        :disabled="uploading || !batchLayer"
                        label="Attach to feature:"
                        dense
                        clearable
                        hide-details
                        outlined
                        @change="batchApply"
                      >
                        <template #append>
                          <v-tooltip max-width="400" bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-1"
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-question-circle
                              </v-icon>
                            </template>
                            <span>
                              <p>Attach to Feature</p>
                            </span>
                          </v-tooltip>
                        </template>
                      </v-autocomplete>

                      <p v-else class="mt-4">&ndash;</p>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="grey lighten-2">
                  <!-- <v-btn text @click="cancelBatch(dialog)"> Cancel </v-btn>-->

                  <v-spacer />

                  <v-btn color="primary" @click="applyBatch(dialog)"> Apply </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-card-text>
      </v-expand-transition>

      <v-divider v-if="fileList.length > 0" />

      <v-card-actions v-if="fileList.length > 0" class="grey lighten-2">
        <v-btn text :disabled="uploading" @click="close">Cancel</v-btn>

        <v-spacer />

        <small v-if="uploadDisabled" class="error--text mr-1">
          All files must be assigned to layers and files which require a coordinate
          system must be set.
        </small>

        <v-btn
          class="ma-2 upload-btn"
          :loading="progress.visible"
          :disabled="uploadDisabled || uploading"
          :outlined="uploadDisabled"
          color="primary"
          @click="upload"
        >
          Upload
          <template #loader>
            <span style="width: 80%">
              <v-progress-linear
                v-if="progress.visible"
                v-model="progress.value"
                background-color="primary darken-1"
                class="ma-0 pa-0"
                color="primary darken-2"
                height="25"
                dark
              >
                <template #default="{ value }">
                  <strong v-if="Math.ceil(value) < 100">{{ Math.ceil(value) }}%</strong>
                  <v-icon v-else pa-1> fal fa-spinner fa-spin </v-icon>
                </template>
              </v-progress-linear>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showError" @click:outside="showError = false">
      <v-alert
        type="error"
        prominent
        border="left"
        transition="scale-transition"
        elevation="2"
        class="ma-0"
      >
        <v-card-text>
          <small class="white--text subtitle-2">
            Please fix the following issues:
          </small>
          <ol>
            <li
              v-for="error in apiError"
              :key="error"
              class="white--text mr-1 error-msgs"
            >
              {{ error }}
            </li>
          </ol>
        </v-card-text>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import { get, sync, call } from "vuex-pathify"

import { cloneDeep, formatFilesize } from "@/utils/general"
import { userHasPerm } from "@/utils/users"

import api from "@/modules/maps/api"

import MapAdd from "@/modules/maps/MapAdd"
import LayerAdd from "@/modules/layers/LayerAdd"

import FilesInformation from "./FilesInformation"
import defaultProjections from "./default-projections.json"

export default {
  name: "FilesUpload",

  components: { FilesInformation, MapAdd, LayerAdd },

  props: {
    visible: { type: Boolean, default: null },
  },

  data: () => ({
    dragover: null,
    fileList: [],
    dialogFormats: null,
    progress: { visible: false, value: 0 },
    apiError: null,
    showError: false,
    search: null,
    searchValue: null,
    availableEpsg: [],
    showMapAdd: false,
    showLayerAdd: false,
    batch: false,

    // Keep in sync with backend settings!
    fileTypes: null,

    map: null,
    loading: null,
    uploading: false,
    mainExtensions: [],
    layerFieldValues: [],
    batchFiles: [],
    availableBatchEpsg: [],
    batchType: null,
    batchLayer: null,
    batchEpsg: null,
    batchAttachWhere: null,
    typeColours: {
      vector: { background: "blue", dark: true },
      raster: { background: "orange", dark: true },
      new: { background: "red", dark: true },
      default: { background: "red", dark: true },
    },
  }),

  computed: {
    activeMap: get("activeMap"),
    organisationId: get("organisationId"),
    user: get("auth/user"),
    maps: get("maps/maps"),
    toast: sync("toast"),

    canAddMap() {
      return this.userHasPerm(this.user, "maps.add_map")
    },

    canAddLayer() {
      return this.userHasPerm(this.user, "layers.add_layer")
    },

    uploadDisabled() {
      return Object.values(this.fileList).some(
        (value) =>
          (value.epsgRequired && value.epsg === null) ||
          (`${value.name.split(".").pop()}`.toLowerCase() !== "zip" &&
            !value.fileType) ||
          !value.layer ||
          (value.fileType === "attachment" && !value.attachWhere)
      )
    },

    batchFileTypes() {
      let batchFileTypes = []
      for (const file of this.fileList) {
        if (file.batch && !file.parent) {
          let fileTypes = this.getAvailableFileTypes(file)
          for (const fileType of fileTypes) {
            if (!batchFileTypes.includes(fileType)) batchFileTypes.push(fileType)
          }
        }
      }
      return batchFileTypes
    },

    batchLayers() {
      let layerArr = []
      for (const file of this.fileList) {
        if (file.batch && !file.parent) {
          let fileLayer = this.availableLayers(file)
          layerArr.push(fileLayer)
        }
      }
      if (layerArr.length === 0) return []
      else
        return layerArr.reduce((a, arr) =>
          a.filter((layer) => arr.some((ele) => ele.id === layer.id))
        )
    },

    batchEpsgRequired() {
      let batchFiles = this.fileList.filter((file) => file.batch)

      return Object.values(batchFiles).some((value) => value.epsgRequired)
    },
  },

  watch: {
    visible: {
      handler(value) {
        if (!value) {
          this.$refs.upload.value = null
          this.fileList = []
        } else {
          this.getMaps()
          this.getFileTypes()
        }
      },
      immediate: true,
    },

    fileTypes(value) {
      for (const [key, val] of Object.entries(value)) {
        const extensions = Object.keys(val)
        this.mainExtensions = [...this.mainExtensions, ...extensions]
      }
    },

    maps(value) {
      if (this.activeMap && !this.map) {
        const map = value.find((map) => map.id === this.activeMap.id)
        if (map) this.map = map
      }
    },

    map(value) {
      this.getMapDetail(value.id)
    },

    batchFileTypes(value) {
      if (value.length === 1) this.batchType = value[0]
    },
  },

  created() {
    this.availableEpsg = defaultProjections

    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault()
      },
      false
    )

    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault()
      },
      false
    )
  },

  methods: {
    userHasPerm,
    formatFilesize,

    ...call("maps", { getMaps: "loadMaps", getMapDetail: "loadMapDetail" }),

    batchApply() {
      let fileListNew = []
      for (const file of this.fileList) {
        if (this.batchFiles.includes(file.name)) {
          if (!this.batchLayer) this.batchAttachWhere = null

          file.fileType = this.batchType
          file.layer = this.batchLayer
          file.attachWhere = this.batchAttachWhere
          file.epsg = this.batchEpsg

          this.validateFile(file)
        }

        fileListNew.push(file)
      }

      this.fileList = fileListNew
    },

    mapCreated(map) {
      this.maps.push(map)
      this.map = map
      this.$refs.selectmap.blur()
    },

    batchDisabled(item, index) {
      const ext = `${item.name.split(".").pop()}`.toLowerCase()
      return item.batch && ext !== "zip"
    },

    async setLayerFieldValues(layerId) {
      if (!layerId) return

      let layers = this.activeMap?.layers || []

      const layer = layers.find((layer) => layer.id === layerId)
      const field = layer.attachment_field

      this.activeMap.layers.map(async (layer) => {
        if (layer.id === layerId && !layer.fieldValues) {
          const fieldValues = await this.getFieldValues(layer.id, field)
          layer.fieldValues = fieldValues
        }
      })
    },

    getAttachmentOptions(layerId) {
      if (!layerId) return []

      let layers = this.activeMap?.layers || []
      const layer = layers.find((layer) => layer.id === layerId)

      const field = layer.attachment_field

      let options = [{ text: "All Features", value: "__ALL__,*" }]
      if (field) options.push({ header: "By " + field })

      layer.fieldValues?.forEach((v) =>
        options.push({ text: v, value: `${field},${v}` })
      )
      return options
    },

    async getFieldValues(layerId, field) {
      if (!field) return []

      try {
        const response = await api.getLayerFieldValues(layerId, field)
        if ([200, 201].includes(response.status)) {
          return response.data
        } else {
          this.showToast(`${response.status} Unable to get field values`, "danger")
          return []
        }
      } catch (e) {
        console.error("ERROR", e)
      }
    },

    availableLayers(file) {
      let layers = this.maps.find((map) => map.id === this.map.id).layers || []
      return layers.filter(
        (layer) =>
          this.canEdit(layer) &&
          (layer.type === file.fileType ||
            !layer.type ||
            file.fileType === "attachment" ||
            file.name.split(".").pop() === "zip")
      )
    },

    async layerCreated(layer, file) {
      try {
        const response = await api.attachLayer(this.map.id, layer.id)
        if ([200, 201].includes(response.status)) {
          const fileList = this.fileList

          fileList.map((file) => {
            if (file.name === file.name || file.parent === file.name)
              file.layer = layer.id
          })

          this.maps.map((map) => {
            if (map.id === this.map.id) map.layers.push(layer)
          })
          this.fileList = null
          this.fileList = fileList
          await this.updateChildren(file)
          this.$refs.select[0].blur()
        }
      } catch (e) {
        console.error("Error", e)
      }
    },

    getProjectionMessage(item) {
      if (!item?.epsgRequired && item?.type !== "image/tiff")
        return "Use associated .prj file"
      else return "Use encoded projection (if available)"
    },

    getBatchProjectionMessage() {
      let batchFiles = this.fileList.filter((file) => file.batch)

      if (batchFiles.some((value) => value.type !== "image/tiff"))
        return "Use associated .prj file"
      else return "Use encoded projection (if available)"
    },

    remove(fileName) {
      this.fileList = this.fileList.filter((item) => item.name !== fileName)
      this.addFiles(this.fileList)
    },

    updateChildrenPrj(file) {
      const text = this.getProjectionMessage(file)
      file.epsg = { text, id: null }
      this.updateChildren(file)
    },

    selectAllToBatch() {
      this.batchFiles = []

      this.fileList.map((file) => {
        if (`${file.name.split(".").pop()}`.toLowerCase() === "zip") return
        file.batch = this.batch
        if (file.batch) this.batchFiles.push(file.name)
      })
    },

    addToBatch(file) {
      this.batchFiles = []
      this.fileList.map((f) => {
        if (f.name === file.name || f.parent === file.name) f.batch = file.batch
        if (f.batch) this.batchFiles.push(f.name)
      })

      // if (file.batch) this.batchFiles.push(file.name)
      // else this.batchFiles = this.batchFiles.filter((f) => f !== file.name)
      // console.log(this.batchFiles)
    },

    updateChildren(file) {
      //TODO: Temp fix, Reactivity issue
      const fileList = this.fileList
      if (!file.layer) file.attachWhere = null

      for (const child of fileList.filter((item) => item.parent === file.name)) {
        if (child.parent) {
          child.epsg = file.epsg
          child.layer = file.layer
          child.fileType = file.fileType
          child.attachWhere = file.attachWhere
        }
      }

      if (this.batch && `${file.name.split(".").pop()}`.toLowerCase() !== "zip") {
        this.fileList.map((f) => {
          if (!f.batch || !file.batch) return
          f.fileType = file.fileType
          f.layer = file.layer
          f.epsg = file.epsg
          f.attachWhere = file.attachWhere
        })
      }

      this.fileList = null
      this.fileList = fileList
    },

    typeColour(type) {
      let colour = this.typeColours[type]
      if (!colour) colour = this.typeColours.default
      return colour
    },

    async getFileTypes() {
      try {
        const response = await api.getFileTypes()
        if ([200, 201].includes(response.status)) {
          this.fileTypes = response.data
        } else {
          this.showToast(`${response.status} Unable to get file types`, "danger")
        }
      } catch (e) {
        console.error("ERROR", e)
      }
    },

    getAvailableFileTypes(file) {
      let availableFileTypes = ["attachment"]
      const ext = `.${file.name.split(".").pop()}`.toLowerCase()

      for (const [key, value] of Object.entries(this.fileTypes)) {
        const extensions = Object.keys(value)
        if (extensions.includes(ext) && (!this.layer?.type || this.layer?.type === key))
          availableFileTypes.push(key)
      }
      return availableFileTypes
    },

    getLayerName(layerId) {
      let layers = cloneDeep(this.map?.layers || [])

      return layers.find((layer) => layer.id === layerId)?.name || ""
    },

    // Add Files New

    addFiles(fileList) {
      let newFiles = []
      let files = [...this.fileList, ...fileList]

      // Make unique
      files = files.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)

      for (const file of files) {
        file.epsg = null
        file.batch = !!file.batch
        file.epsgRequired = null

        const ext = `.${file.name.split(".").pop()}`.toLowerCase()
        const filename = file.name.slice(0, -ext.length)

        if (this.mainExtensions.includes(ext)) {
          // Deliberate double loop to set the parent.
          // TODO: solve this more elegantly
          for (const f of files) {
            let availableFileTypes = this.getAvailableFileTypes(file).filter(
              (t) => t !== "attachment"
            )

            let extArr = []
            for (const type of availableFileTypes) {
              extArr = extArr.concat(
                this.fileTypes[type][ext].children.required || [],
                this.fileTypes[type][ext].children.optional || []
              )
            }

            if (
              f.name.slice(0, -ext.length) === filename &&
              f.name !== file.name &&
              extArr.includes(`.${f.name.split(".").pop()}`.toLowerCase())
            )
              f.parent = file.name
          }
        }
      }

      for (const file of files) {
        if (!file.parent && file.fileType) {
          const ext = `.${file.name.split(".").pop()}`.toLowerCase()
          const filename = file.name.slice(0, -ext.length)

          const requiredFileExts = this.fileTypes[file.fileType]
            ? this.fileTypes[file.fileType][ext]?.children?.required
            : []
          const optionalFileExts = this.fileTypes[file.fileType]
            ? this.fileTypes[file.fileType][ext]?.children?.optional
            : []
          const combined = requiredFileExts.concat(optionalFileExts)

          let childFiles = files
            .filter((f) => {
              if (
                f.parent === file.name &&
                combined.includes(`.${f.name.split(".").pop()}`.toLowerCase())
              )
                return f.name
            })
            .map((item) => item.name)

          // Attachments don't need epsg
          if (file.fileType === "attachment") {
            file.epsg = null
            file.epsgRequired = null
            //
            // File type requires a .prj but we ain't got none.
          } else if (
            requiredFileExts.includes(".prj") &&
            !childFiles.includes(`${filename}.prj`)
          ) {
            file.epsgRequired = true
            //
            // We have a .prj or it's a tif/tiff.
            // TODO: this looks messy. Why are we checking optional and required?
          } else if (
            (optionalFileExts.includes(".prj") || requiredFileExts.includes(".prj")) &&
            (childFiles.includes(`${filename}.prj`) ||
              ext === ".tif" ||
              ext === ".tiff")
          ) {
            file.epsgRequired = false
          } else {
            file.epsgRequired = null
          }
        }

        // It's not a child file and not a main extension
        if (!file.parent) {
          const fileTypes = this.getAvailableFileTypes(file)
          if (fileTypes.length === 1) file.fileType = fileTypes[0]
          newFiles.push(file)
        }

        const childFiles = files.filter((f) => f.parent === file.name)
        if (childFiles.length > 0) newFiles = [...newFiles, ...childFiles]
      }

      this.fileList = newFiles
    },

    validateFile(file) {
      if (file.fileType) {
        let files = this.fileList
        const ext = `.${file.name.split(".").pop()}`.toLowerCase()
        const filename = file.name.slice(0, -ext.length)
        const requiredFileExts = this.fileTypes[file.fileType]
          ? this.fileTypes[file.fileType][ext]?.children?.required
          : []

        const optionalFileExts = this.fileTypes[file.fileType]
          ? this.fileTypes[file.fileType][ext]?.children?.optional
          : []
        let combined = requiredFileExts.concat(optionalFileExts)
        let childFiles = files
          .filter((f) => {
            if (
              f.parent === file.name &&
              combined.includes(`.${f.name.split(".").pop()}`.toLowerCase())
            )
              return f.name
          })
          .map((item) => item.name)
        if (file.fileType === "attachment") {
          file.epsg = null
          file.epsgRequired = null
        } else if (
          requiredFileExts.includes(".prj") &&
          !childFiles.includes(`${filename}.prj`)
        )
          file.epsgRequired = true
        else if (
          (optionalFileExts.includes(".prj") || requiredFileExts.includes(".prj")) &&
          (childFiles.includes(`${filename}.prj`) || ext === ".tif" || ext === ".tiff")
        )
          file.epsgRequired = false
        else file.epsgRequired = null
      }

      this.updateChildren(file)
    },

    select(e) {
      this.addFiles([...e.target.files])
    },

    drop(e) {
      this.dragover = false
      this.addFiles([...e.dataTransfer.files])
    },

    click() {
      this.dragover = false
      this.$refs.upload.click()
    },

    isOrgAdmin(layer) {
      return this.user.organisations.find((item) => item.id === layer.organisation_id)
        ?.admin
    },

    canEdit(layer) {
      return (
        (this.user.id === layer.owner.id ||
          (layer.visibility === "ORG" && this.isOrgAdmin(layer))) &&
        this.userHasPerm(this.user, "layers.change_layer")
      )
    },

    change() {
      this.dragover = false
    },

    close() {
      this.fileList = []
      this.$refs.upload.value = null
      this.$emit("cancel")
    },

    async fileExists(file) {
      try {
        await new Response(file.slice(0, 1)).text()
        return true
      } catch (error) {
        return false
      }
    },

    async upload() {
      this.uploading = true
      let formData = new FormData()
      let error = false
      this.apiError = []
      for (const file of this.fileList) {
        if (await this.fileExists(file)) {
          const layerName = this.getLayerName(file.layer)
          const fileType =
            !file.parent && file.fileType && file.fileType !== "attachment"
              ? file.fileType
              : ""
          // When doing a formdata post like we do here, `null` is being transmitted as
          // the string "null". So instead we use an empty string and check for that.
          const layerId = file.layer
          const epsg = file.epsg?.id || 0

          formData.append("uploads", file)
          formData.append("uploads_layer_ids", layerId)
          formData.append("uploads_layer_names", layerName)
          formData.append("uploads_epsgs", epsg)
          formData.append("uploads_attach", file.attachWhere || "")
          formData.append("uploads_types", fileType) // TEMP - remove when upload form field done
        } else {
          this.apiError.push(`${file.name}: error reading file.`)
          this.fileList = this.fileList.filter((item) => item.name !== file.name)

          error = true
        }
      }

      if (error) {
        this.uploading = false
        return
      }

      try {
        this.progress.visible = true
        let config = {
          onUploadProgress: async (progressEvent) => {
            this.progress.value = Math.ceil(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          },
        }
        const response = await this.axios.patch(
          `/maps/${this.map.id}/`,
          formData,
          config
        )

        if ([200, 201].includes(response.status)) {
          await this.$router.push({
            name: "Map",
            params: { id: this.map.id },
          })
        }
      } catch (error) {
        this.showError = true
        this.progress = { visible: false, value: 0 }
        if (error.response?.status && error.response?.data) {
          const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray))

          this.apiError = concat(
            error.response.data.upload,
            error.response.data.uploads_epsgs,
            error.response.data.uploads_types
          )
        } else this.apiError.push("Unknown api error")
      }
      this.uploading = false
    },

    addItem(ele) {
      const obj = { text: ele, id: ele }
      this.availableEpsg.push({ ...obj })
    },
    searchInput($event) {
      this.searchValue = $event
    },

    showToast(message, color = "error") {
      this.toast.show = true
      this.toast.color = color
      this.toast.text = message
    },

    resetBatch() {
      let fileListNew = []
      for (const item of this.fileList) {
        item.batch = false
        fileListNew.push(item)
      }
      this.fileList = fileListNew

      this.batch = []
      this.batchFiles = []
    },

    /*cancelBatch(dialog) {
      this.resetBatch()
      dialog.value = false
    },*/

    applyBatch(dialog) {
      this.resetBatch()
      dialog.value = false
    },
  },
}
</script>

<style scoped>
.error-msgs {
  list-style-type: decimal;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}
</style>
