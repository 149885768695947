import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import colors from "vuetify/es5/util/colors"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      complete: "fal fa-check",
      cancel: "fal fa-times-circle",
      close: "fal fa-times",
      delete: "fal fa-times-circle", // delete (e.g. v-chip close)
      clear: "fal fa-times-circle", // delete (e.g. v-chip close)
      success: "fal fa-check-circle",
      info: "fal fa-info-circle",
      warning: "fal fa-exclamation",
      error: "fal fa-exclamation-triangle",
      prev: "fal fa-chevron-left",
      next: "fal fa-chevron-right",
      checkboxOn: "fal fa-check-square",
      checkboxOff: "fal fa-square",
      checkboxIndeterminate: "fal fa-minus-square",
      delimiter: "fal fa-circle", // for carousel
      sort: "fal fa-angle-up",
      expand: "fal fa-angle-down",
      menu: "fal fa-bars",
      subgroup: "fal fa-angle-down",
      dropdown: "fal fa-angle-down",
      radioOn: "fal fa-dot-circle",
      radioOff: "fal fa-circle",
      edit: "fal fa-edit",
      ratingEmpty: "fal fa-star",
      ratingFull: "fal fa-star",
      ratingHalf: "fal fa-star-half",
      loading: "fal fa-sync",
      first: "fal fa-step-backward",
      last: "fal fa-step-forward",
      unfold: "fal fa-arrows-alt-v",
      file: "fal fa-paperclip",
      plus: "fal fa-plus",
      minus: "fal fa-minus",
    },
  },
})
