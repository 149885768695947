<template>
  <div style="display: contents">
    <v-btn v-if="showBtn" color="primary" small @click="$emit('input', true)">
      <v-icon class="mr-1" small> fal fa-plus-circle </v-icon>
      Create
    </v-btn>

    <v-dialog
      id="add-map-popup"
      :value="value"
      max-width="80%"
      @click:outside="$emit('input', false)"
    >
      <v-card class="create-map">
        <v-card-title class="green white--text"> Add a Map </v-card-title>

        <v-card-text class="mt-6">
          <map-form ref="form" v-model="map" :edit="value" />
        </v-card-text>

        <v-card-actions class="pt-0 pb-4">
          <v-btn color="grey" text outlined @click="$emit('input', false)">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn id="save-btn" :loading="saving" color="primary" @click="save()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify"
import mapApi from "./api"
import MapForm from "@/modules/maps/MapForm"

// @vuese
// This component used to create new map
export default {
  name: "AddMap",
  components: { MapForm },
  props: {
    value: { type: Boolean, default: false },
    showBtn: { type: Boolean, default: false },
  },

  data() {
    return {
      saving: false,
      map: {
        zoom_min: 0,
        zoom_max: 21,
        bounds: [],
        extent: [],
      },
    }
  },

  computed: {
    // @vuese
    // Get the organisationID from the store.
    // @arg yes
    organisationId: get("organisationId"),
    refreshMapSwitcher: sync("refreshMapSwitcher"),
  },

  watch: {
    // @vuese
    // Initialise form on popup renders
    value() {
      this.errors = {}

      this.$nextTick(() => {
        this.map = {
          zoom_min: 0,
          zoom_max: 21,
          bounds: [],
          extent: [],
        }
      })
    },
  },

  methods: {
    async save() {
      this.saving = true
      try {
        let map = this.$refs.form.save()
        let response = await this.$store.dispatch("maps/createMap", map)
        if (response.status == 201) {
          this.$emit("done", response.data)
          this.$emit("input", false)
          this.refreshMapSwitcher = true
          this.saving = false
        } else {
          this.$emit("error", response?.statusText || "Unknown API Error")
          this.saving = false
        }
      } catch (e) {
        this.saving = false
        console.error(e)
      }
    },
  },
}
</script>
