import Vue from "vue"

export default {
  uploadLayer(formData, layer_id, type) {
    const endpoint = type === "attachment" ? "upload_attachment" : "upload_file"
    return Vue.axios
      .post(`/layers/${layer_id}/${endpoint}/`, formData)
      .then((response) => response)
      .catch((error) => error.response)
  },

  getLayers(page, sortBy, sortDesc, items = 100) {
    const ordering = (sortDesc ? "-" : "") + sortBy
    const params = { page, ordering, items }
    return Vue.axios
      .get("/layers/", { params })
      .then((response) => response.data)
      .catch((error) => error.response)
  },

  async getOrganisationLayers(orgId, params = { items: 100 }) {
    if (!orgId) {
      const message = `layers.api: getOrganisationLayers failed. Organisation Id: ${orgId}`
      console.error(message)
      return message
    }
    try {
      const response = await Vue.axios.get(`/organisations/${orgId}/layers/`, {
        params,
      })
      return response.data
    } catch (error) {
      return error.response
    }
  },

  getLayerDetails(layerId) {
    return Vue.axios
      .get(`/layers/${layerId}/`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  getLayerStatus(layerId) {
    return Vue.axios
      .get(`/layers/${layerId}/status`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  editLayer(layer) {
    if (layer.id) {
      return Vue.axios
        .patch(`/layers/${layer.id}/`, layer)
        .then((response) => response)
        .catch((error) => error.response)
    } else {
      return Vue.axios
        .post("/layers/", layer)
        .then((response) => response)
        .catch((error) => error.response)
    }
  },

  getLayerFiles(sortBy, sortDesc, mapId, layerId) {
    const ordering = (sortDesc ? "-" : "") + sortBy
    const params = { details: true, ordering }
    return Vue.axios
      .get(`/layers/${layerId}/files/`, {
        params,
      })
      .then((response) => response)
      .catch((error) => error.response)
  },

  checkIfFileExists(layerId, filename) {
    return Vue.axios
      .get(`/layers/${layerId}/download_original/?file=${filename}`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  downloadFiles(mapId, params) {
    return Vue.axios
      .get(`/maps/${mapId}/files/?download`, {
        params,
        responseType: "blob",
      })
      .then((response) => response)
      .catch((error) => error.response)
  },

  deleteMultipleFile(layerId, params) {
    return Vue.axios
      .delete(`/layers/${layerId}/files/delete_multi/`, {
        params,
      })
      .then((response) => response)
      .catch((error) => error.response)
  },

  deleteFile(layerId, file) {
    return Vue.axios
      .delete(`/layers/${layerId}/files/${file}`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  deleteLayer(layer) {
    return Vue.axios
      .delete("/layers/" + layer.id)
      .then((response) => response)
      .catch((error) => error.response)
  },
}
