<template>
  <span class="ml-2 d-flex">
    <v-btn
      v-if="inSettings"
      id="close-admin-btn"
      :to="{ name: 'Home' }"
      color="primary"
      outlined
      small
    >
      <v-icon class="mr-1" small>fal fa-times</v-icon>&nbsp;
      {{ $t("close") }}
    </v-btn>

    <files-upload-button v-if="inMapView && canUpload" />

    <v-btn
      v-if="activeMap && !inSettings && canAdmin"
      :to="{ name: 'Map', params: { id: activeMap.id } }"
      :icon="$vuetify.breakpoint.xsOnly"
      class="settings__button"
      color="primary"
      outlined
      small
    >
      <v-icon :class="{ 'mr-1': $vuetify.breakpoint.smAndUp }" small>
        fal fa-cog </v-icon
      >&nbsp;
      <span v-if="$vuetify.breakpoint.smAndUp">{{ $t("admin") }}</span>
    </v-btn>
  </span>
</template>

<script>
import { get } from "vuex-pathify"
import { userHasPerm } from "@/utils/users"

import FilesUploadButton from "@/modules/files/FilesUploadButton"

export default {
  name: "MainNavigation",

  components: {
    FilesUploadButton,
  },

  computed: {
    user: get("auth/user"),
    activeMap: get("activeMap"),

    organisationId: get("organisationId"),

    inSettings() {
      return !["MapView", "MapShare", "Home"].includes(this.$route.name)
    },

    inMapView() {
      return this.$route.name === "MapView"
    },

    canAdmin() {
      if (this.user) {
        for (const perm of [
          "layers.add_layer",
          "layers.change_layer",
          "maps.add_map",
          "maps.change_map",
          "users.add_user",
          "users.change_user",
          "organisations.add_organisation",
          "organisations.change_organisation",
        ])
          if (this.userHasPerm(this.user, perm)) return true
      }
      return false
    },

    userIsAdmin() {
      const organisationId =
        this.organisationId || localStorage.getItem("organisationId")
      if (!this.user || !this.user.organisations) return false

      // Get the organisation IDs where the user is an admin. This will be checked on the backend also, obviously.
      const adminOrgs = this.user.organisations
        .filter((org) => org.admin)
        .map((org) => org.id)

      return adminOrgs.includes(organisationId)
    },

    canUpload() {
      return (
        this.userHasPerm(this.user, "layers.upload") ||
        // DEPRECATED: Remove once we have migrated fully to layer.upload permission
        this.userHasPerm(this.user, "layers.upload_geodata")
        //
      )
      return false
    },
  },

  methods: {
    userHasPerm,
  },
}
</script>
