import Vue from "vue"

export default {
  getOrganisations() {
    return Vue.axios
      .get(`/organisations/`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  listOrganisations(page, sortBy, sortDesc, items = 100) {
    const ordering = (sortDesc ? "-" : "") + sortBy
    const params = { page, ordering, items }
    return Vue.axios
      .get("/organisations/", { params })
      .then((response) => response.data)
      .catch((error) => error.response)
  },

  createOrganisation(organisation) {
    return Vue.axios
      .post("/organisations/", organisation)
      .then((response) => response)
      .catch((error) => error.response)
  },

  getOrganisationDetails(orgId) {
    return Vue.axios
      .get(`/organisations/${orgId}/`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  editOrganisation(organisation) {
    return Vue.axios
      .patch(`/organisations/${organisation.id}/`, organisation)
      .then((response) => response)
      .catch((error) => error.response)
  },
}
