<template>
  <div
    class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field"
    style="width: 100%"
  >
    <div class="v-input__slot">
      <div class="v-text-field__slot" style="width: 100%">
        <label
          v-if="label"
          class="v-label v-label--active theme--light"
          style="position: absolute"
        >
          {{ label }}
        </label>
        <div
          :class="{
            outlined,
            standard: !outlined,
            'grey lighten-4 multi-line': multiLine,
          }"
          style="overflow-y: auto; max-height: 400px; width: 100%"
        >
          <span
            v-if="
              (value && typeof value === 'string' && value.trim().length > 0) ||
              typeof value !== 'string'
            "
          >
            <slot />
          </span>
          <!--  eslint-disable-next-line vue/no-v-html      -->
          <span v-else-if="empty" v-html="empty" />
          <!--          Skipped  -->
          <span v-else><slot /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextFieldStatic",

  props: {
    dark: { type: Boolean, default: false },
    empty: { type: String, default: "&ndash;" },
    label: { type: String, default: "" },
    light: { type: Boolean, default: false },
    multiLine: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    value: {
      type: [Object, Number, String, Boolean, Array],
      required: false,
      default: null,
    },
  },
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.standard {
  margin-top: 3px;
}

/*noinspection CssUnusedSymbol*/
.outlined {
  margin-top: 5px;
}

/*noinspection CssUnusedSymbol*/
.multi-line {
  padding: 3px 1px;
  border-radius: 5px;
}
</style>
