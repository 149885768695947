<template>
  <div style="display: contents">
    <v-menu offset-y close-on-click>
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="ml-2 user-menu"
          icon
          outlined
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="ml-1 mr-n2" color="primary" small> fal fa-user </v-icon>
          <v-icon class="ml-n2" color="primary" x-small> fal fa-angle-down </v-icon>
        </v-btn>
      </template>
      <v-list class="pa-2" nav dense>
        <v-list-item id="change-password" @click="showPasswordDialog(true)">
          <v-list-item-icon class="mr-2">
            <v-icon small color="primary"> fal fa-lock-alt </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Change Password
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item id="logout-btn" @click="logout">
          <v-list-item-icon class="mr-2">
            <v-icon small color="primary"> fal fa-sign-out-alt </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text"> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--    <user-password :value="passwordDialog" @dialog="showPasswordDialog" />-->
    <user-password v-model="passwordDialog" />
  </div>
</template>

<script>
import UserPassword from "@/modules/users/UserPassword"

export default {
  name: "UserMenu",

  components: { UserPassword },

  data: () => ({
    passwordDialog: null,
  }),

  methods: {
    showPasswordDialog(value) {
      this.passwordDialog = value
    },
    logout() {
      this.$router.push({ name: "Logout" })
    },
  },
}
</script>
