<template>
  <v-navigation-drawer
    v-if="theme"
    v-model="drawer"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    :style="{ 'z-index': $vuetify.breakpoint.smAndDown ? 5 : 2 }"
    :right="theme.rtl"
    width="300"
    app
    clipped
    class="elevation-8"
  >
    <admin-sidebar v-if="$route.meta.module === 'admin' || $route.name === 'Home'" />
    <map-sidebar v-if="$route.meta.module === 'maps'" />
  </v-navigation-drawer>
</template>

<script>
import { get, sync } from "vuex-pathify"

import AdminSidebar from "@/modules/core/AdminSidebar"
import MapSidebar from "@/modules/maps/MapSidebar"

export default {
  name: "Sidebar",

  components: { MapSidebar, AdminSidebar },

  computed: {
    // get status of the sidebar drawer
    drawer: sync("drawer"),

    // check RTL enabled, stored in localStorage
    theme: get("layout"),
  },
}
</script>

<style scoped>
.v-navigation-drawer
  >>> .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  -webkit-transform: none;
  transform: none;
}

.v-input--selection-controls {
  margin-top: 8px;
  padding-top: 0px;
  margin-bottom: -18px;
}

.v-input--selection-controls.v-input {
  flex: 1 1 auto;
}
</style>
