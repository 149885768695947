import Vue from "vue"
import store from "@/store"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: store.state.lang,
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages: {
    en: require("../locales/en.json"),
    fr: require("../locales/fr.json"),
    ga: require("../locales/ga.json"),
  },
})

export function loadCustom() {
  const locale = store.state.lang
  i18n.setLocaleMessage(locale, i18n.messages[locale])
  i18n.locale = locale
}
