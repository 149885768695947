import { make } from "vuex-pathify"
import api from "@/modules/users/api"

const state = {
  users: [],
  loading: false,
}

const mutations = {
  ...make.mutations(state),
}

const getters = {
  ...make.getters(state),

  userById(state) {
    const user = state.users.map((item) => ({ [item.id]: item }))
    return Object.assign({}, ...user)
  },
}

const actions = {
  ...make.actions("users"),

  async getUsers({ commit, rootState }) {
    if (!rootState.organisationId) return
    commit("SET_LOADING", true)
    try {
      const response = await api.getUsers(rootState.organisationId)
      if ([200, 201].includes(response?.status)) {
        commit("SET_USERS", response.data)
      }
      commit("SET_LOADING", false)
      return response
    } catch (e) {
      throw e
    }
  },

  async loadUserDetail({ commit, state }, userId) {
    commit("SET_LOADING", true)
    try {
      const response = await api.getUserDetails(userId)
      if (response.status === 200) {
        let updatedUser = response.data
        let allUsers = [...state.users]
        if (allUsers.length > 0) {
          allUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))
        } else allUsers.push(updatedUser)
        commit("SET_USERS", allUsers)
      }
      commit("SET_LOADING", false)
      return response
    } catch (e) {
      throw e
    }
  },

  async createUser({ commit, state, rootState }, user) {
    if (!rootState.organisationId) return
    user.organisation = rootState.organisationId

    try {
      const response = await api.createUser(user)
      if ([200, 201].includes(response?.status)) {
        let users = [...state.users]
        users.push(response.data)
        commit("SET_USERS", users)
      }
      return response
    } catch (e) {
      throw e
    }
  },

  async updateUser({ commit, state, rootState }, user) {
    if (!rootState.organisationId) return
    user.organisation = rootState.organisationId
    try {
      const response = await api.editUser(user)
      if ([200, 201].includes(response?.status)) {
        let users = state.users.filter((usr) => usr.id !== user.id)
        users.push(user)
        commit("SET_USERS", users)
      }
      return response
    } catch (e) {
      throw e
    }
  },

  async deleteUser({ commit, state }, targetUser) {
    try {
      const response = api.deleteUser(targetUser)
      if (response.status === 204) {
        let users = state.users.filter((usr) => usr.id !== targetUser.id)
        commit("SET_MAPS", users)
      }
      return response
    } catch (e) {
      throw e
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
