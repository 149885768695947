import { make } from "vuex-pathify"
import api from "@/modules/maps/api"
import localforage from "@/plugins/localforage"

const state = {
  loader: [],
  maps: [],
  maplibre: null,
  loading: false,
  edit: false,
  mapShareData: null,
  startTour: false,
  permissionError: false,
  mapPopup: {
    dialog: true,
    feature: {},
    dtm: {},
  },
}

const mutations = {
  ...make.mutations(state),

  async SET_MAPS(state, maps) {
    /*
    Our List and Detail map endpoints return some/all fields respectively for
    performance reasons (backend). Merge map objects when updating so we keep
    as much data as possible. Preference given to fields in the 'new' map object
    over the one currently in state.
    */
    maps.map(async (map) => {
      try {
        map.uiLayerStatus = (await localforage.getItem(`layerStatus_${map.id}`)) || {}
        if (map.uiLayerStatus) {
          let layerStatus = map.uiLayerStatus
          for (const layer of map.layers) {
            if (layer.id in layerStatus) {
              layer.enabled = layerStatus[layer.id]
              layer.available = layer.enabled
            }
          }
        }

        const bgLayer = await localforage.getItem(`bg_layer${map.id}`)
        map.bg_layer = bgLayer === null ? 1 : bgLayer
      } catch (e) {}
    })
    if (!state.maps) state.maps = maps
    else {
      let mergedMaps = []
      for (let map of maps) {
        let existingMap = state.maps.find((m) => m.id == map.id)
        if (existingMap) {
          map = { ...existingMap, ...map }
        }
        mergedMaps.push(map)
      }
      state.maps = mergedMaps
    }
  },
}

const getters = {
  ...make.getters(state),

  maps(state, getters, rootState) {
    let allMaps = []
    if (
      state.maps &&
      state.maps.length > 0 &&
      state.maps[0].organisation_id == rootState.organisationId
    )
      allMaps = state.maps
    return allMaps
  },
  mapsById(state) {
    const mapped = state.maps.map((item) => ({ [item.id]: item }))
    return Object.assign({}, ...mapped)
  },

  layers(state) {
    let layers = {}
    if (state.maps) {
      state.maps.forEach((map) => {
        map.layers?.forEach((layer) => (layers[layer.id] = layer))
      })
    }
    return Object.values(layers)
  },
}

const actions = {
  ...make.actions("maps"),
  ...make.actions("maplibre"),
  ...make.actions("edit"),

  async loadMaps({ commit, rootState }) {
    if (rootState.organisationId) {
      commit("SET_LOADING", true)

      const response = await api.getOrganisationMaps(rootState.organisationId)
      if ([200, 201].includes(response.status)) {
        const maps = response?.data || []

        maps.forEach((map, idx, arr) => {
          map.organisation_id = rootState.organisationId
          arr[idx] = map
        })

        commit("SET_MAPS", maps)
      } else if (response.status === 403) commit("SET_PERMISSION_ERROR", true)
      else console.error(response)

      commit("SET_LOADING", false)
    }
  },

  loadMapDetail({ commit, state, getters }, mapId) {
    commit("SET_LOADING", true)
    let existingMap = getters.mapsById[mapId]
    let lastMod = existingMap?._lastMod?.toUTCString() || null
    return new Promise((resolve, reject) => {
      api.getMapDetails(mapId, lastMod).then((response) => {
        if (response?.status === 200) {
          let updatedMap = response.data
          updatedMap._lastMod = new Date(
            response.headers["last-modified"]
              ? Date.parse(response.headers["last-modified"])
              : null
          )
          let allMaps = [...state.maps]
          if (allMaps.length > 0) {
            allMaps.forEach((map, idx, arr) => {
              if (map.id == updatedMap.id) {
                arr[idx] = updatedMap
              }
            })
          } else allMaps.push(updatedMap)
          commit("SET_MAPS", allMaps)
        }
        commit("SET_LOADING", false)
        resolve()
      })
    })
  },

  createMap({ commit, state, rootState }, map) {
    map.organisation_id = rootState.organisationId
    return new Promise((resolve, reject) => {
      api.createMap(map).then((response) => {
        if ([200, 201].includes(response?.status)) {
          let allMaps = [...state.maps]
          allMaps.push(response.data)
          commit("SET_MAPS", allMaps)
          resolve(response)
        }
        // ERROR
        reject(response)
      })
    })
  },

  updateMap({ commit, state }, map) {
    return new Promise((resolve, reject) => {
      api.editMap(map).then((response) => {
        if (response?.status === 200) {
          let updatedMap = response.data
          let allMaps = [...state.maps]
          allMaps.forEach((map, idx, arr) => {
            if (map.id == updatedMap.id) {
              arr[idx] = updatedMap
            }
          })
          commit("SET_MAPS", allMaps)
          resolve(response)
        }
        // ERROR
        reject(response)
      })
    })
  },

  deleteMap({ commit, state }, targetMap) {
    return new Promise((resolve, reject) => {
      api.deleteMap(targetMap).then((response) => {
        if (response?.status === 204) {
          let allMaps = [...state.maps]
          allMaps.forEach((map, idx, arr) => {
            if (map.id == targetMap.id) {
              arr.splice(idx, 1)
            }
          })
          commit("SET_MAPS", allMaps)
          resolve(response)
        }
        // ERROR
        reject(response)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
