<template>
  <div>
    <v-btn small text @click="$emit('input', !value)">
      <v-icon v-if="!right" class="mr-2" small>fal fa-chevron-double-left</v-icon>
      Hide
      <v-icon v-if="right" class="ml-2" small>
        fal fa-chevron-double-right
        {{ $vuetify.breakpoint.xsOnly && "fa-rotate-90" }}</v-icon
      >
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Collapse",

  props: {
    value: { type: Boolean, default: true },
    right: { type: Boolean, default: false },
  },
}
</script>
