<template>
  <span>
    <v-autocomplete
      v-if="expand && maps.length > 1"
      v-model="map"
      v-click-outside="outside"
      :items="maps"
      :menu-props="{
        openOnFocus: true,
        openOnHover: true,
      }"
      class="ml-1 map-selector"
      color="primary"
      item-text="name"
      item-value="id"
      dense
      hide-details
      outlined
    />

    <div v-else class="mx-0 px-0">
      <v-btn
        v-if="maps.length > 1"
        aria-label="Maps"
        class="mx-0 px-0"
        color="primary"
        text
        @click="expand = true"
      >
        <v-icon class="mr-1">fal fa-map</v-icon>
        <v-icon>fal fa-angle-down</v-icon>
      </v-btn>
      <span v-if="$vuetify.breakpoint.smAndUp && mapObj">{{ mapObj.title }}</span>
    </div>
  </span>
</template>

<script>
import { get, sync } from "vuex-pathify"

export default {
  name: "SwitchMap",

  data: () => ({
    expand: false,
  }),

  computed: {
    map: sync("activeMap"),
    maps: get("maps/maps"),

    mapObj() {
      return this.maps?.find((map) => map.id === this.map)
    },
  },

  watch: {
    map(id, oldId) {
      if (!id || !oldId) return
      this.$router.push({ name: "MapView", params: { id } }, () => {})
    },
  },

  methods: {
    outside() {
      this.expand = false
    },
  },
}
</script>
