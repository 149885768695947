<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" width="900px">
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          x-small
          color="primary"
          class="doc-btn"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> fal fa-file-code</v-icon>
        </v-btn>
      </template>
      <v-card elevation="2">
        <v-toolbar dense color="primary" class="fixed-bar white--text">
          <span class="text-h5"> Popup code </span>
          <v-spacer />
          <v-btn icon x-small @click="dialog = false">
            <v-icon color="white"> fal fa-times-circle</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <Editor
            v-model="code"
            :highlight="highlight"
            :line-numbers="lineNumbers"
            :readonly="readonly"
            class="my-editor mb-0"
            language="html"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { PrismEditor } from "vue-prism-editor"
import { highlight, languages } from "prismjs/components/prism-core"

import "prismjs/components/prism-clike"
import "prismjs/components/prism-markup"

import "vue-prism-editor/dist/prismeditor.min.css"
import "@/stylus/editorstyles.css"

import SimplePopup from "@/modules/layers/templates/Simple-popup-doc.json"

export default {
  name: "PopupCodeDocument",
  components: {
    Editor: PrismEditor,
  },
  data: () => ({
    dialog: false,
    lineNumbers: false,
    readonly: true,
    code: "",
  }),

  created() {
    this.code = SimplePopup.code
  },

  methods: {
    highlight(code) {
      return highlight(
        code,
        {
          ...languages["markup"],
        },
        "markup"
      )
    },
  },
}
</script>

<style scoped>
.doc-btn {
  /*position: absolute;*/
  margin-top: -9px;
  margin-right: 10px;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky;
  top: 0em;
  z-index: 2;
}

.prism-editor__textarea:focus {
  outline: none;
}

.my-editor {
  background-color: #fafafa;
  max-height: 400px;
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 18px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.36) !important;
}
</style>
