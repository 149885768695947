<template>
  <div>
    <v-card>
      <v-card-title
        class="primary white--text"
        style="position: sticky; top: 0; z-index: 2"
      >
        Upload files

        <v-spacer />

        <v-btn dark icon @click="close">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-5 pt-0">
        <input
          id="fileUpload"
          ref="upload"
          type="file"
          style="display: none"
          :disabled="uploading"
          multiple
          @input="select"
        />
        <v-sheet
          id="dropzone"
          ref="dzone"
          :color="dragover ? 'primary lighten-3' : null"
          :disabled="uploading"
          tabindex="0"
          width="100%"
          class="pa-2"
          style="cursor: pointer; border: 2px dashed grey"
          @dragover.native.prevent="dragover = true"
          @dragenter.native.prevent="dragover = true"
          @dragleave.native.prevent="dragover = false"
          @dragend.native.prevent="drop"
          @drop.native.prevent="drop"
          @click.native.prevent="click"
          @change.native.prevent="change"
        >
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" class="py-2 d-flex justify-center align-center">
                <v-icon v-if="dragover" color="secondary"> fal fa-layer-plus </v-icon>
                <v-icon v-else color="secondary"> fal fa-cloud-upload </v-icon>

                <span
                  v-if="dragover"
                  style="min-height: 49px"
                  class="ml-3 title secondary--text"
                >
                  Add files
                </span>
                <span v-else class="ml-3 text-body-1 secondary--text">
                  Drag &amp; drop files here <br />or click to select files to upload
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>

        <v-row>
          <v-col cols="12" class="text-center pb-0 mb-0">
            <v-dialog v-model="dialogFormats" width="50%" scrollable>
              <template #activator="{ on, attrs }">
                <small class="primary--text text-center" v-bind="attrs" v-on="on">
                  <v-icon color="primary" x-small> fal fa-question-circle </v-icon>
                  More information about file formats
                </small>
              </template>

              <files-information v-model="dialogFormats" />
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="fileList.length > 0" class="mt-0 pt-0">
        <v-row>
          <v-col cols="12" class="mt-0 pt-0">
            <v-simple-table
              :height="fileList.length < 8 ? null : 420"
              class="pt-0 mt-0"
              fixed-header
            >
              <template #default>
                <thead>
                  <tr>
                    <th class="subtitle-2 black--text">Name</th>
                    <th class="subtitle-2 black--text text-end">Size</th>
                    <th class="subtitle-2 black--text">Type</th>
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in fileList" :key="`${item.name}-${index}`">
                    <td>
                      <v-icon v-if="item.parent" class="ml-2 mr-1" small>
                        fal fa-level-up fa-rotate-90
                      </v-icon>
                      {{ item.name }}
                    </td>

                    <td class="text-end text-no-wrap">
                      {{ formatFilesize(item.size) }}
                    </td>

                    <td class="text-center">
                      <span v-if="item.name.split('.').pop() === 'zip'">
                        Auto
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon color="primary"> fal fa-question-circle </v-icon>
                            </v-btn>
                          </template>
                          <span> Detect file type by processor </span>
                        </v-tooltip>
                      </span>
                      <v-select
                        v-else-if="
                          getAvailableFileTypes(item).length > 0 && !item.parent
                        "
                        v-model="item.fileType"
                        :items="getAvailableFileTypes(item)"
                        :disabled="uploading"
                        label="File Type"
                        hide-details
                        solo
                        dense
                        @change="validateFile(item)"
                      />
                      <span v-else-if="item.fileType">{{ item.fileType }}</span>
                      <span v-else>&ndash;</span>
                    </td>

                    <td class="text-center">
                      <v-combobox
                        v-if="
                          !item.parent &&
                          item.fileType &&
                          item.fileType !== 'zip' &&
                          item.fileType !== 'attachment'
                        "
                        v-model="item.epsg"
                        :items="availableEpsg"
                        :search-input="search"
                        :return-object="true"
                        :label="
                          item.epsgRequired
                            ? 'Select a standard coordinate system or enter an EPSG code'
                            : getProjectionMessage(item)
                        "
                        :disabled="uploading"
                        item-value="id"
                        item-text="text"
                        hide-selected
                        solo
                        dense
                        clearable
                        hide-details
                        @update:search-input="searchInput"
                        @change="updateChildren(item)"
                      >
                        <template #no-data>
                          <v-list-item @click.stop="addItem(searchValue)">
                            <span class="subheading">Create</span>
                            <v-chip label small>
                              {{ searchValue }}
                            </v-chip>
                          </v-list-item>
                        </template>

                        <template v-if="!item.epsgRequired" #append-item>
                          <v-list-item ripple @click="updateChildrenPrj(item)">
                            <v-list-item-content>
                              <v-list-item-title>
                                <strong>{{ getProjectionMessage(item) }}</strong>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <!--  eslint-disable-next-line vue/no-template-shadow -->
                        <template #selection="{ item }">
                          {{ item.text }}
                          <span v-if="item.id"> ({{ item.id }}) </span>
                        </template>

                        <template #append>
                          <v-tooltip max-width="400" bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-1"
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-question-circle
                              </v-icon>
                            </template>
                            <div>
                              <p>
                                Any geospatial file that should be displayed on the map
                                needs a coordinate system definition, so that the Portal
                                knows how to transform it.
                              </p>
                              <p>
                                The Portal is using the internationally recognised EPSG
                                coding system for coordinate systems, projections and
                                transformations.
                              </p>
                              See https://epsg.io
                            </div>
                          </v-tooltip>
                        </template>
                      </v-combobox>

                      <template v-else-if="item.fileType === 'attachment'">
                        <v-autocomplete
                          v-model="item.attachWhere"
                          :items="attachmentOptions"
                          label="Attach to feature:"
                          solo
                          dense
                          clearable
                          hide-details
                          @change="updateChildren(item)"
                        >
                          <template #append>
                            <v-tooltip max-width="400" bottom>
                              <template #activator="{ on, attrs }">
                                <v-icon
                                  class="ml-1"
                                  color="info"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  fal fa-question-circle
                                </v-icon>
                              </template>
                              <p>Attach to Feature</p>
                            </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </template>
                    </td>

                    <td>
                      <v-btn
                        :disabled="uploading"
                        icon
                        small
                        @click="remove(item.name)"
                      >
                        <v-icon small>fal fa-times-circle</v-icon>
                      </v-btn>
                    </td>

                    <td>
                      <v-tooltip v-if="item.fileError" bottom>
                        <template #activator="{ on }">
                          <v-icon color="error" class="mt-2" v-on="on">
                            fal fa-exclamation-triangle
                          </v-icon>
                        </template>
                        <v-card-subtitle class="pa-1 text-center">
                          Error
                        </v-card-subtitle>
                        <v-divider color="black" />
                        <v-card-text class="pa-1">
                          <li v-for="error in item.fileError" :key="error">
                            {{ error }}
                          </li>
                        </v-card-text>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider v-if="fileList.length > 0" />

      <v-card-actions v-if="fileList.length > 0" class="grey lighten-2">
        <v-btn :disabled="uploading" text @click="close">Cancel</v-btn>
        <v-spacer />
        <small v-if="uploadDisabled" class="error--text mr-1">
          All files must be assigned to layers and files which require a coordinate
          system must be set.
        </small>
        <v-btn
          id="upload-btn"
          :loading="progress.visible"
          :disabled="uploadDisabled || uploading"
          :outlined="uploadDisabled"
          class="ma-2"
          color="primary"
          @click="upload"
        >
          Upload
          <template #loader>
            <span style="width: 80%">
              <v-progress-linear
                v-if="progress.visible"
                v-model="progress.value"
                background-color="primary darken-1"
                class="ma-0 pa-0"
                color="primary darken-2"
                height="25"
                dark
              >
                <template #default="{ value }">
                  <strong v-if="Math.ceil(value) < 100">{{ Math.ceil(value) }}%</strong>
                  <v-icon v-else pa-1> fal fa-spinner fa-spin </v-icon>
                </template>
              </v-progress-linear>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showError" @click:outside="showError = false">
      <v-alert
        type="error"
        prominent
        border="left"
        transition="scale-transition"
        elevation="2"
        class="ma-0"
      >
        <v-card-text>
          <small class="white--text subtitle-2"> Fix following issues.. </small>
          <ol>
            <li
              v-for="error in apiError"
              :key="error"
              class="white--text mr-1 error-msgs"
            >
              {{ error }}
            </li>
          </ol>
        </v-card-text>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify"

import { formatFilesize } from "@/utils/general"
import api from "@/modules/maps/api"
import { userHasPerm } from "@/utils/users"
import FilesInformation from "@/modules/files/FilesInformation"
import defaultProjections from "./default-projections.json"

export default {
  name: "LayerFilesUpload",
  components: { FilesInformation },
  props: {
    visible: { type: Boolean, default: null },
    layer: { type: Object, default: () => {} },
  },

  data: () => ({
    dragover: null,
    fileList: [],
    fileLayers: {},
    dialogFormats: null,
    progress: { visible: false, value: 0 },
    apiError: null,
    showError: false,
    search: null,
    searchValue: null,
    availableEpsg: [],
    // Keep in sync with backend settings!
    fileTypes: [],
    mainExtensions: [],

    maps: [],
    map: null,
    loading: null,
    uploading: false,
    layerFieldValues: {},
  }),

  computed: {
    activeMap: get("activeMap"),
    organisationId: get("organisationId"),
    user: get("auth/user"),
    toast: sync("toast"),

    uploadDisabled() {
      return Object.values(this.fileList).some(
        (value) =>
          (value.epsgRequired && value.epsg === null) ||
          (`${value.name.split(".").pop()}`.toLowerCase() !== "zip" &&
            !value.fileType) ||
          !value.layer ||
          (value.fileType === "attachment" && !value.attachWhere)
      )
    },

    attachmentOptions() {
      let options = [{ text: "All Features", value: "__ALL__,*" }]

      const field = this.layer.attachment_field

      if (this.layer.attachment_field) {
        options.push({ header: `By ${field}` })

        for (const value of this.layerFieldValues[field])
          options.push({ text: value, value: `${field},${value}` })
      }

      return options
    },
  },

  watch: {
    visible: {
      handler(value) {
        this.apiError = null
        this.fileList = []
        if (!value) return
        this.getMaps()
        this.getFileTypes()
      },
      immediate: true,
    },

    fileTypes(value) {
      for (const val of Object.values(value)) {
        const extensions = Object.keys(val)
        this.mainExtensions = [...this.mainExtensions, ...extensions]
      }
    },
  },

  created() {
    this.availableEpsg = defaultProjections
    if (this.layer.attachment_field) this.getFieldValues(this.layer.attachment_field)
  },

  methods: {
    userHasPerm,
    formatFilesize,

    async getFieldValues(field) {
      try {
        const response = await api.getLayerFieldValues(this.layer.id, field)
        if ([200, 201].includes(response.status)) {
          this.layerFieldValues[field] = response.data
        } else {
          this.showToast(`${response.status} Unable to get field values`, "danger")
        }
      } catch (e) {
        console.error("ERROR", e)
      }
    },

    async getFileTypes() {
      try {
        const response = await api.getFileTypes()
        if ([200, 201].includes(response.status)) {
          this.fileTypes = response.data
        } else {
          this.showToast(`${response.status} Unable to get file types`, "danger")
        }
      } catch (e) {
        console.error("ERROR", e)
      }
    },

    getProjectionMessage(item) {
      if (!item?.epsgRequired && item?.type !== "image/tiff")
        return "Use associated .prj file"
      else return "Use encoded projection (if available)"
    },

    remove(fileName) {
      this.fileList = this.fileList.filter((item) => item.name !== fileName)
      this.addFiles(this.fileList)
    },

    updateChildrenPrj(file) {
      const text = this.getProjectionMessage(file)
      file.epsg = { text, id: null }
      this.updateChildren(file)
    },

    getAvailableFileTypes(file) {
      let availableFileTypes = ["attachment"]
      const ext = `.${file.name.split(".").pop()}`.toLowerCase()

      for (const [key, value] of Object.entries(this.fileTypes)) {
        const extensions = Object.keys(value)
        if (extensions.includes(ext) && (!this.layer?.type || this.layer?.type === key))
          availableFileTypes.push(key)
      }
      return availableFileTypes
    },

    updateChildren(file) {
      //TODO: Temp fix, Reactivity issue
      const fileList = this.fileList

      for (const child of fileList.filter((item) => item.parent === file.name)) {
        if (child.parent) {
          child.epsg = file.epsg
          child.fileType = file.fileType
        }
      }

      this.fileList = null
      this.fileList = fileList
    },

    async getMaps() {
      if (!this.organisationId) return

      if (!this.activeMap) this.activeMap = await this.localStore.getItem("activeMap")

      this.loading = true

      const response = await api.getOrganisationMaps(this.organisationId)
      this.maps = response?.data || []

      if (this.activeMap) {
        const map = this.maps.find((map) => map.id === this.activeMap)
        if (map) this.map = map
      }

      this.loading = false
    },

    addFiles(fileList) {
      let newFiles = []
      let files = [...this.fileList, ...fileList]
      files = files.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)

      for (const file of files) {
        file.epsg = null
        file.epsgRequired = null
        file.layer = this.layer.id

        const ext = `.${file.name.split(".").pop()}`.toLowerCase()
        const filename = file.name.slice(0, -ext.length)

        if (this.mainExtensions.includes(ext)) {
          // Deliberate double loop to set the parent.
          // TODO: solve this more elegantly
          for (const f of files) {
            let availableFileTypes = this.getAvailableFileTypes(file).filter(
              (t) => t !== "attachment"
            )

            let extArr = []
            for (const type of availableFileTypes) {
              extArr = extArr.concat(
                this.fileTypes[type][ext].children.required || [],
                this.fileTypes[type][ext].children.optional || []
              )
            }

            if (
              f.name.slice(0, -ext.length) === filename &&
              f.name !== file.name &&
              extArr.includes(`.${f.name.split(".").pop()}`.toLowerCase())
            )
              f.parent = file.name
          }
        }
      }

      for (const file of files) {
        if (!file.parent && file.fileType) {
          const ext = `.${file.name.split(".").pop()}`.toLowerCase()
          const filename = file.name.slice(0, -ext.length)

          const requiredFileExts = this.fileTypes[file.fileType]
            ? this.fileTypes[file.fileType][ext]?.children?.required
            : []
          const optionalFileExts = this.fileTypes[file.fileType]
            ? this.fileTypes[file.fileType][ext]?.children?.optional
            : []
          const combined = requiredFileExts.concat(optionalFileExts)

          let childFiles = files
            .filter((f) => {
              if (
                f.parent === file.name &&
                combined.includes(`.${f.name.split(".").pop()}`.toLowerCase())
              )
                return f.name
            })
            .map((item) => item.name)

          // Attachments don't need epsg
          if (file.fileType === "attachment") {
            file.epsg = null
            file.epsgRequired = null
            //
            // File type requires a .prj but we ain't got none.
          } else if (
            requiredFileExts.includes(".prj") &&
            !childFiles.includes(`${filename}.prj`)
          ) {
            file.epsgRequired = true
            //
            // We have a .prj or it's a tif/tiff.
            // TODO: this looks messy. Why are we checking optional and required?
          } else if (
            (optionalFileExts.includes(".prj") || requiredFileExts.includes(".prj")) &&
            (childFiles.includes(`${filename}.prj`) ||
              ext === ".tif" ||
              ext === ".tiff")
          ) {
            file.epsgRequired = false
          } else {
            file.epsgRequired = null
          }
        }

        if (!file.parent) newFiles.push(file)

        const childFiles = files.filter((f) => f.parent === file.name)
        if (childFiles.length > 0) newFiles = [...newFiles, ...childFiles]
      }

      this.fileList = newFiles
    },

    validateFile(file) {
      if (file.fileType) {
        let files = this.fileList
        const ext = `.${file.name.split(".").pop()}`.toLowerCase()
        const filename = file.name.slice(0, -ext.length)
        const requiredFileExts = this.fileTypes[file.fileType]
          ? this.fileTypes[file.fileType][ext]?.children?.required
          : []

        const optionalFileExts = this.fileTypes[file.fileType]
          ? this.fileTypes[file.fileType][ext]?.children?.optional
          : []

        let combined = requiredFileExts.concat(optionalFileExts)
        let childFiles = files
          .filter((f) => {
            if (
              f.parent === file.name &&
              combined.includes(`.${f.name.split(".").pop()}`.toLowerCase())
            )
              return f.name
          })
          .map((item) => item.name)
        if (file.fileType === "attachment") {
          file.epsg = null
          file.epsgRequired = null
        } else if (
          requiredFileExts.includes(".prj") &&
          !childFiles.includes(`${filename}.prj`)
        )
          file.epsgRequired = true
        else if (
          (optionalFileExts.includes(".prj") || requiredFileExts.includes(".prj")) &&
          (childFiles.includes(`${filename}.prj`) || ext === ".tif" || ext === ".tiff")
        )
          file.epsgRequired = false
        else file.epsgRequired = null
      }

      this.updateChildren(file)
    },

    select(e) {
      this.addFiles([...e.target.files])
    },

    drop(e) {
      this.dragover = false
      this.addFiles([...e.dataTransfer.files])
    },

    click() {
      this.dragover = false
      this.$refs.upload.click()
    },

    change() {
      this.dragover = false
    },

    close() {
      this.fileList = []
      this.$emit("cancel")
    },

    addItem(ele) {
      const obj = { text: ele, id: ele }
      this.availableEpsg.push({ ...obj })
    },

    searchInput(value) {
      this.searchValue = value
    },

    async fileExists(file) {
      try {
        await new Response(file.slice(0, 1)).text()
        return true
      } catch (error) {
        return false
      }
    },

    async upload() {
      this.uploading = true
      let formData = new FormData()
      let error = false
      this.apiError = []
      for (const file of this.fileList) {
        const epsg = file.epsg?.id || 0
        if (await this.fileExists(file)) {
          const fileType =
            !file.parent && file.fileType && file.fileType !== "attachment"
              ? file.fileType
              : ""
          formData.append("uploads", file)
          formData.append("uploads_epsgs", epsg)
          formData.append("uploads_attach", file.attachWhere || "")
          // TODO:  only main files (eg. dxf, shp etc) should have a type, otherwise "" (eg. prj, .dbf, .shx files)
          formData.append("uploads_types", fileType) // TEMP: hardcode 'vector' type for testing
        } else {
          this.apiError.push(`${file.name}: error reading file.`)
          this.fileList = this.fileList.filter((item) => item.name !== file.name)
          delete this.fileLayers[file.name]
          error = true
        }
      }
      if (error) return

      try {
        // QUICKFIX: quite nasty because this should fail if the Layer is not on the
        // activeMap. Needs to be tackled very soon.
        if (!this.map && this.activeMap) {
          console.error(
            `FilesUploadLayer: map was not set, so setting it to
             activeMap ${this.activeMap.id}`
          )
          const response = await api.getOrganisationMaps(this.organisationId)
          this.maps = response?.data || []

          const map = this.maps.find((map) => map.id === this.activeMap.id)
          if (map) this.map = map
        }
        this.progress.visible = true
        let config = {
          onUploadProgress: async (progressEvent) => {
            this.progress.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          },
        }
        const response = await this.axios.patch(
          `/layers/${this.layer.id}/`,
          formData,
          config
        )

        if ([200, 201].includes(response.status)) {
          this.progress = { visible: false, value: 0 }
          this.$emit("done")
        }
      } catch (error) {
        this.showError = true
        this.progress = { visible: false, value: 0 }

        if (error.response?.status && error.response?.data) {
          const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray))
          this.apiError = concat(
            error.response.data.upload,
            error.response.data.uploads_epsgs,
            error.response.data.uploads_types
          )
        } else this.apiError.push("Unknown api error")
      }
      this.uploading = false
    },

    showToast(message, color = "error") {
      this.toast.show = true
      this.toast.color = color
      this.toast.text = message
    },
  },
}
</script>

<style scoped>
.error-msgs {
  list-style-type: decimal;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}
</style>
