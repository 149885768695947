import Vue from "vue"
import Rollbar from "vue-rollbar"

if (process.env.NODE_ENV === "production") {
  Vue.use(Rollbar, {
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    source_map_enabled: true,
    environment: "production",
    payload: {
      client: {
        javascript: {
          code_version: process.env.VUE_APP_VERSION,
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
    },

    ignoredMessages: ["Loading CSS chunk auth failed"],

    // https://docs.rollbar.com/docs/source-maps#section-using-source-maps-on-many-domains
    transform: function (payload) {
      const trace = payload.body.trace
      // This just removes any hostname
      const locRegex = /^(https?):\/\/[^\/]+(.*)/
      if (trace && trace.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
          const filename = trace.frames[i].filename
          if (filename) {
            const m = locRegex.exec(filename)
            if (!m) {
              console.error(`Couldn't rewrite filename URL for ${filename}`)
              continue
            }

            // Be sure that the minified_url when uploading includes 'dynamichost'
            trace.frames[i].filename = m[1] + "://dynamichost" + m[2]
          }
        }
      }
    },
  })

  Vue.config.errorHandler = function (err) {
    Vue.rollbar.error(err)
  }
}
