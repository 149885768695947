<template>
  <div class="text-center">
    <div v-if="impersonateActive">
      <div>{{ user.first_name }} {{ user.last_name }}</div>
      <v-btn id="btn-release" color="error" x-small @click="release()"> Release </v-btn>
    </div>

    <v-select
      v-else
      v-model="userId"
      :items="users"
      item-value="id"
      label="Login as..."
      dense
      hide-details
      outlined
    >
      <template slot="selection" slot-scope="data">
        {{ data.item.first_name }} {{ data.item.last_name }}
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.first_name }} {{ data.item.last_name }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { get, sync, call } from "vuex-pathify"
import AuthApi from "@/modules/auth/api"

//  @vuese
//  Impersonate user component
// @group Users
export default {
  name: "UserImpersonate",

  data: () => ({
    userId: null,
  }),

  computed: {
    ...get("auth", ["user"]),
    impersonateActive: sync("impersonateActive"),
    users: get("users/users"),
  },

  watch: {
    userId(value) {
      this.impersonate(value)
    },
  },

  created() {
    this.getUsers()
  },

  methods: {
    ...call("users", ["getUsers"]),
    ...call("auth", ["getProfile"]),

    async impersonate(userId) {
      // TODO: put in store action
      const response = await AuthApi.impersonate(userId)
      if (response.status === 200) {
        this.impersonateActive = true

        // We explicitly reload the user to ensure the impersonated User is set.
        await this.getProfile()

        window.location.replace("/")
      } else {
        console.error(response)
      }
    },

    async release() {
      // TODO: put in store action
      const response = await AuthApi.releaseImpersonate()
      if ([200, 403].includes(response.status)) {
        await this.localStore.removeItem("impersonateActive")

        // We explicitly reload the user to ensure the impersonated User is set.
        await this.getProfile()

        window.location.replace("/")
      } else {
        console.error(response)
      }
    },
  },
}
</script>
