import Vue from "vue"
import { formatDistanceToNow, format, parseISO } from "date-fns"
import cloneDeep from "lodash/fp/cloneDeep"

// Helper function to load pre-compiled component from url
export function externalComponent(url) {
  return new Promise((resolve, reject) => {
    const name = url
      .split("/")
      .reverse()[0]
      .match(/^(.*?)\.umd/)[1]
    const script = document.createElement("script")
    script.async = true
    script.addEventListener("load", () => {
      resolve(window[name])
    })
    script.addEventListener("error", () => {
      reject(new Error(`Error loading ${url}`))
    })
    script.src = url
    document.head.appendChild(script)
  })
}

// Helper function to read JSON file
export function getJSON(url, callback) {
  let xhr = new XMLHttpRequest()
  xhr.onload = function () {
    callback(this.responseText)
  }
  xhr.ontimeout = function () {
    return false
  }
  xhr.onerror = function () {
    return false
  }
  xhr.open("GET", url, true)
  xhr.send()
}

/*
export function downloadFile(url, name) {
  url = url.slice(4)
  url = `${url}?download`
  Vue.axios.get(url, { responseType: "blob" }).then((response) => {
    let fileURL = window.URL.createObjectURL(new Blob([response.data]))
    let fileLink = document.createElement("a")

    fileLink.href = fileURL
    fileLink.setAttribute("download", name)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}
*/

export function fileExists(url) {
  let http = new XMLHttpRequest()
  if (!window.XMLHttpRequest) {
    http = new ActiveXObject("Microsoft.XMLHTTP")
  }

  http.open("HEAD", url, false)
  http.send()

  http.ontimeout = function () {
    return false
  }
  http.onerror = function () {
    return false
  }

  return http.status == 200
}

export function formatFilesize(bytes) {
  const exp = (Math.log(bytes) / Math.log(1024)) | 0
  const result = (bytes / Math.pow(1024, exp)).toFixed(0)
  return result + (exp === 0 ? "b" : " " + "KMGTPEZY"[exp - 1] + "B")
}

export function formatDate(value, dateStr = "dd/MM/yyyy") {
  return format(new Date(value), dateStr)
}

export function formatTime(value) {
  if (!value) return null

  const date = new Date(value)
  return date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  })
}

export function countDown(value) {
  return formatDistanceToNow(parseISO(value))
}

export function isUserAllowed(control) {
  if (this.$store.get("auth/user") && this.$store.get("auth/user").permissions)
    return this.$store.get("auth/user").permissions.includes(control)
  else return false
}

export function getHostUrl() {
  return `${window.location.protocol}//${window.location.host}`
}

// TODO This function is not using at the moment
// Projections are reading from predefined projections.json file
// In future need to re-write this using axios

export function getProjection(epsg, callback) {
  const url = `https://epsg.io/${epsg}.proj4`
  let xhr = new XMLHttpRequest()
  xhr.onload = function () {
    callback(this.response)
  }
  xhr.ontimeout = function () {
    return false
  }
  xhr.onerror = function () {
    return false
  }
  xhr.open("GET", url, true)
  xhr.send()
}

export function sendDataToHoneycomb(event) {
  //////////////////////////////////////////////////////////////////////////////////////
  //      !!! Honeycomb deactivated because we don't use it anymore. !!!              //
  //////////////////////////////////////////////////////////////////////////////////////
  // Honeycomb implementation
  /*if (process.env.NODE_ENV === "production") {
      const honeycombWriteKey = process.env.VUE_APP_HONEYCOMB_KEY
      const honeycombDatasetName = "Portal"
      const event_obj = {
        service_name: "frontend",
        user_agent: window.navigator.userAgent,
        asset_version: process.env.VUE_APP_VERSION,
        user_id: JSON.parse(localStorage.getItem("user"))
          ? JSON.parse(localStorage.getItem("user")).id
          : null,
        impersonate_active: localStorage.getItem("impersonateActive"),
      }
      const data = { ...event, ...event_obj }
  
      let xhr = new XMLHttpRequest()
      xhr.open(
        "POST",
        "https://api.honeycomb.io/1/events/" + encodeURIComponent(honeycombDatasetName),
        true
      )
      xhr.setRequestHeader("X-Honeycomb-Team", honeycombWriteKey)
      xhr.send(JSON.stringify(data))
    }*/
}

export function nl2br(value) {
  if (!value) return ""
  return value.replace(/\n/g, "<br>")
}

export { cloneDeep }

export function deleteItem(module, id) {
  return Vue.axios
    .delete(`/${module}/${id}/`)
    .then((response) => response)
    .catch((error) => error.response)
}

export async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => ("00" + b.toString(16)).slice(-2)).join("")
  return hashHex
}

export function updateFavIcon(logo) {
  let link =
    document.querySelector("link[rel*='icon']") || document.createElement("link")
  link.type = "image/x-icon"
  link.rel = "shortcut icon"
  link.href = logo
  document.getElementsByTagName("head")[0].appendChild(link)
}
