<template>
  <div>
    <!--  eslint-disable vue/no-v-html      -->
    <text-field-static
      v-if="fieldStatic || readonly || disabled"
      :class="{ 'body-2': smallText }"
      :dark="dark"
      :empty="empty"
      :label="label"
      :light="light"
      :multi-line="multiLine"
      :outlined="outlined"
      :value="value"
    >
      <slot>
        <span
          v-if="empty && empty.length > 0 && (!value || value.length === 0)"
          v-html="empty"
        />
        <span
          v-else-if="multiLine"
          :class="{ 'body-2': smallText }"
          style="max-height: 15rem; overflow-y: auto"
          v-html="valueHtml"
        />
        <span v-else>{{ value }}</span>
      </slot>
    </text-field-static>
    <!--    Skipped    -->
    <v-text-field
      v-if="!fieldStatic && !readonly && !disabled && !multiLine"
      :class="{ 'body-2': smallText }"
      :label="required ? label + '*' : label"
      :rules="localRules"
      :readonly="timeField ? timeField : readonly"
      v-bind="{
        appendIcon,
        autoGrow,
        color,
        dark,
        dense,
        filled,
        hideDetails,
        errorMessages,
        hint,
        light,
        multiLine,
        name,
        outlined,
        persistentHint,
        prefix,
        prependIcon,
        required,
        rows,
        solo,
        suffix,
        textarea,
        type,
        validateOnBlur,
        value,
        flat,
      }"
      @input="input"
      @click:append="clickAppend"
      v-on="activator"
    />

    <v-textarea
      v-else-if="!fieldStatic && !readonly && !disabled && multiLine"
      :class="{ 'body-2': smallText }"
      :label="required ? label + '*' : label"
      :rules="localRules"
      v-bind="{
        appendIcon,
        autoGrow,
        color,
        dark,
        dense,
        filled,
        hideDetails,
        hint,
        light,
        multiLine,
        name,
        outlined,
        readonly,
        persistentHint,
        prefix,
        required,
        rows,
        solo,
        suffix,
        textarea,
        type,
        validateOnBlur,
        value,
      }"
      @input="input"
      v-on="activator"
    />
  </div>
</template>

<script>
import TextFieldStatic from "./TextFieldStatic.vue"
import { nl2br } from "@/utils/general"

export default {
  name: "TextField",

  components: { TextFieldStatic },

  props: {
    disabled: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    empty: { type: String, default: "&ndash;" },
    static: { type: Boolean, default: false },
    activator: { type: Object, default: null },
    smallText: { type: Boolean, default: false },
    errorMessages: { type: String, default: "" },

    // v-bind
    appendIcon: { type: String, default: "" },
    autoGrow: { type: Boolean, default: false },
    clickAppend: { type: Function, default: () => {} },
    color: { type: String, default: null },
    dark: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    hint: { type: String, default: "" },
    label: { type: String, default: "" },
    light: { type: Boolean, default: false },
    multiLine: { type: Boolean, default: false },
    name: { type: String, default: "" },
    outlined: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    prefix: { type: String, default: "" },
    prependIcon: { type: String, default: undefined },
    readonly: { type: Boolean, default: false },
    timeField: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rows: { type: [Number, String], default: 0 },
    rules: { type: [Array, String], default: () => [] },
    solo: { type: Boolean, default: false },
    suffix: { type: String, default: "" },
    textarea: { type: Boolean, default: false },
    type: { type: String, default: "" },
    validateOnBlur: { type: Boolean, default: true },
    value: {
      type: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: "",
    },
  },

  computed: {
    localRules() {
      if (this.rules.length > 0) return this.rules
      else if (this.required) return [() => !!this.value || "This field is required"]
      return []
    },

    fieldStatic() {
      return this.static
    },

    computedValue() {
      if (this.emptyValue) return this.empty
      return this.value
    },

    computedHTML() {
      if (this.emptyValue) return true
      return this.html
    },

    emptyValue() {
      return (
        !this.value ||
        (typeof this.value === "string" &&
          this.value.length === 0 &&
          this.empty.length !== 0)
      )
    },
    valueHtml() {
      return this.nl2br(this.value)
    },
  },

  methods: {
    nl2br,

    input(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.text-center::v-deep input {
  text-align: center;
}
.text-right::v-deep input {
  text-align: right;
}
</style>
