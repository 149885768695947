<template>
  <v-dialog v-model="dialog" max-width="1000px" width="80%">
    <template #activator="{ on, attrs }">
      <v-btn
        id="add-files"
        :icon="$vuetify.breakpoint.xsOnly"
        :outlined="$vuetify.breakpoint.xsOnly"
        color="primary"
        class="mr-2"
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :class="{ 'mr-1': $vuetify.breakpoint.smAndUp }" small>
          fal fa-upload </v-icon
        >&nbsp;
        <span v-if="$vuetify.breakpoint.smAndUp">Upload</span>
      </v-btn>
    </template>

    <layer-files-upload
      v-if="layer && layer.id"
      :layer="layer"
      :visible="dialog"
      @done="uploaded"
      @cancel="dialog = false"
    />
    <files-upload v-else :visible="dialog" @cancel="dialog = false" />
  </v-dialog>
</template>

<script>
import FilesUpload from "@/modules/files/FilesUpload"
import LayerFilesUpload from "@/modules/files/FilesUploadLayer"

export default {
  name: "FileUploadButton",

  components: {
    LayerFilesUpload,
    FilesUpload,
  },

  props: {
    layer: { type: Object, default: () => {} },
  },

  data: () => ({
    dialog: null,
  }),

  methods: {
    uploaded() {
      this.dialog = false
      this.$emit("done")
    },
  },
}
</script>
