import Vue from "vue"
import VueAnalytics from "vue-analytics"
import router from "@/router"

Vue.use(VueAnalytics, {
  id: "UA-139474310-1",
  // autoTracking: {
  //   screenview: true
  // },
  debug: {
    sendHitTask: process.env.NODE_ENV === "production",
  },
  router,
})
