// noinspection JSUnresolvedVariable

import Vue from "vue"

export default {
  async getMapById(id) {
    try {
      return await Vue.axios.get(`/maps/${id}/`)
    } catch (error) {
      return error.response
    }
  },

  async createMap(map) {
    try {
      return await Vue.axios.post("/maps/", map)
    } catch (error) {
      return error.response
    }
  },

  async deleteMap(map) {
    try {
      return await Vue.axios.delete(`"/maps/${map.id}/`)
    } catch (error) {
      return error.response
    }
  },

  async getMaps(page, sortBy, sortDesc, items = 100) {
    const params = { page, items }
    try {
      return await Vue.axios.get("/maps/", { params })
    } catch (error) {
      return error.response
    }
  },

  /**
   * Hit the Map Detail endpoint - /api/maps/<mapid>/
   * @param {string} mapId - Maps hashid
   * @param {string|null} lastMod - Formatted if-modified-since header value. Eg. 'Wed, 21 Oct 2015 07:28:00 GMT'
   * @return {Promise} - Axios request promise
   */
  async getMapDetails(mapId, lastMod = null) {
    let headers = {}
    if (lastMod) headers["If-Modified-Since"] = lastMod

    try {
      return await Vue.axios.get(`/maps/${mapId}/`, { headers })
    } catch (error) {
      return error.response
    }
  },

  async editMap(map) {
    try {
      return await Vue.axios.patch(`/maps/${map.id}/`, map)
    } catch (error) {
      return error.response
    }
  },

  async attachLayer(mapId, layerId) {
    try {
      return await Vue.axios.patch(`/maps/${mapId}/layer/${layerId}/`)
    } catch (error) {
      return error.response
    }
  },

  async detachLayer(mapId, layerId) {
    try {
      return await Vue.axios.delete(`/maps/${mapId}/layer/${layerId}/`)
    } catch (error) {
      return error.response
    }
  },

  async getOrganisationMaps(orgId, params = { items: 100 }) {
    if (!orgId) {
      const message = `maps.api: getOrganisationMaps failed. Organisation Id: ${orgId}`
      console.error(message)
      return
    }
    try {
      return await Vue.axios.get(`/organisations/${orgId}/maps/`, { params })
    } catch (error) {
      return error.response
    }
  },

  async searchMap(mapId, data) {
    try {
      return await Vue.axios.get(`/maps/${mapId}/search/`, { params: data })
    } catch (error) {
      return error.response
    }
  },

  async getLayerAttachment(layerId, fid) {
    const params = { type: "attachment", fid, details: true }
    try {
      return await Vue.axios.get(`/layers/${layerId}/files/`, { params })
    } catch (error) {
      return error.response
    }
  },

  async getLayerFieldValues(layerId, field) {
    // TODO: Do we want this and this endpoint? Why not on the layer itself?
    try {
      return await Vue.axios.get(`/layers/${layerId}/field-values/${field}/`)
    } catch (error) {
      return error.response
    }
  },

  async getSection(mapId, section) {
    try {
      return await Vue.axios.get(`/maps/${mapId}/section/`, { params: section })
    } catch (error) {
      return error.response
    }
  },

  async getDownload(mapId, data) {
    try {
      return await Vue.axios.get(`/maps/${mapId}/download/`, { params: data })
    } catch (error) {
      return error.response
    }
  },

  async getDownloadableFiles(mapId, search = null, bounds = null) {
    let params = {}

    if (search) params.search = search

    if (bounds?.length > 0) {
      params.sw_lon = bounds[0]
      params.sw_lat = bounds[1]
      params.ne_lon = bounds[2]
      params.ne_lat = bounds[3]
    }

    try {
      return await Vue.axios.get(`maps/${mapId}/files/`, { params })
    } catch (error) {
      return error.response
    }
  },

  async downloadFiles(mapId, params) {
    try {
      return await Vue.axios.get(`/maps/${mapId}/files/?download`, {
        params,
        responseType: "blob",
      })
    } catch (error) {
      return error.response
    }
  },

  async mapShare(data) {
    try {
      return await Vue.axios.post(`/mapshares/`, data)
    } catch (error) {
      return error.response
    }
  },

  async getCoordinateSystems() {
    try {
      return await Vue.axios.get(`/maps/coordinate-systems/`)
    } catch (error) {
      return error.response
    }
  },

  async getFileTypes() {
    try {
      return await Vue.axios.get(`/types/`)
    } catch (error) {
      return error.response
    }
  },
}
