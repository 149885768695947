<template>
  <v-card v-if="mapShareData" width="100%" class="my-0 py-0" flat>
    <v-card-title class="pt-2 pb-0" color="primary" dark>
      <v-toolbar-title>Map Info</v-toolbar-title>
    </v-card-title>

    <v-card-text>
      <v-row align="center" class="pa-0 ma-0">
        <v-col class="py-1 subtitle-2"> Link Valid Until </v-col>
        <v-col class="py-1 text-right">
          {{ formatDate(mapShareData.expiry) }}
          {{ formatTime(mapShareData.expiry) }}
        </v-col>
      </v-row>

      <v-row align="center" class="pa-0 ma-0">
        <v-col class="py-1 subtitle-2">Shared By</v-col>
        <v-col class="py-1 text-right">
          {{ mapShareData.created_by.first_name }}
          {{ mapShareData.created_by.last_name }}
        </v-col>
      </v-row>

      <v-row v-if="mapShareData.bounds.length > 0" align="center" class="pa-0 ma-0">
        <v-col class="py-2 text-center">Map bounds are restricted</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatDate, formatTime } from "@/utils/general"

export default {
  name: "MapShareInfo",

  computed: {
    mapShareData() {
      return this.$store.get("maps/mapShareData")
    },
  },

  methods: {
    formatDate,
    formatTime,
  },
}
</script>

<style scoped></style>
