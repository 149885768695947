// noinspection JSUnresolvedVariable

import Vue from "vue"
import Router from "vue-router"

import { setAnalyticsProfile, setAuthToken } from "@/modules/auth/store"
import api from "@/modules/auth/api"

import store from "../store"

import Home from "../modules/home/Home.vue"

////////////////////////////////////////////////////////////////////////////////////////
// Patching Router.push because we are not interested in navigation failures.
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

////////////////////////////////////////////////////////////////////////////////////////

/////////////////
// Map View

const MapView = () =>
  import(/* webpackChunkName: "mapview" */ "../modules/maps/MapView.vue")

/////////////////
// Maps

const Maps = () => import(/* webpackChunkName: "maps" */ "../modules/maps/Maps.vue")
const Map = () => import(/* webpackChunkName: "maps" */ "../modules/maps/Map.vue")
const Mapshare = () =>
  import(/* webpackChunkName: "maps" */ "../modules/maps/MapView.vue")

/////////////////
// Layers

const Layers = () =>
  import(/* webpackChunkName: "layers" */ "../modules/layers/Layers.vue")
const Layer = () =>
  import(/* webpackChunkName: "layers" */ "../modules/layers/Layer.vue")

/////////////////
// Auth

const Login = () => import(/* webpackChunkName: "auth" */ "../modules/auth/Login.vue")
const Logout = () => import(/* webpackChunkName: "auth" */ "../modules/auth/Logout.vue")
const PasswordReset = () =>
  import(/* webpackChunkName: "auth" */ "../modules/auth/PasswordReset.vue")
const PasswordResetConfirm = () =>
  import(/* webpackChunkName: "auth" */ "../modules/auth/PasswordResetConfirm.vue")

/////////////////
// Users

const Users = () => import(/* webpackChunkName: "users" */ "../modules/users/Users.vue")
const User = () => import(/* webpackChunkName: "users" */ "../modules/users/User.vue")

/////////////////
// Organisations

const Organisations = () =>
  import(
    /* webpackChunkName: "organisations" */ "../modules/organisations/Organisations.vue"
  )
const Organisation = () =>
  import(
    /* webpackChunkName: "organisations" */ "../modules/organisations/Organisation.vue"
  )

/////////////////
// Core

const FileNotFound = () =>
  import(/* webpackChunkName: "core" */ "../components/FileNotFound.vue")
const PermissionDenied = () =>
  import(/* webpackChunkName: "core" */ "../components/PermissionDenied.vue")

////////////////////////////////////////////////////////////////////////////////////////

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
      meta: { loginRequired: true, module: "home" },
    },

    {
      path: "/maps/",
      name: "Maps",
      component: Maps,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/maps/:id/",
      name: "MapView",
      component: MapView,
      meta: { loginRequired: true, module: "maps" }, // , pathToRegexpOptions: { strict: true }
    },

    {
      path: "/maps/:id/detail/",
      name: "Map",
      component: Map,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/maps/:id/share/:token/",
      name: "MapShare",
      component: Mapshare,
      meta: { loginRequired: true, module: "maps" }, // , pathToRegexpOptions: { strict: true }
    },

    {
      path: "/layers/",
      name: "Layers",
      component: Layers,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/layers/:id/",
      name: "Layer",
      component: Layer,
      props: true,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/users/",
      name: "Users",
      component: Users,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/user/:id/",
      name: "User",
      component: User,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/organisations/",
      name: "Organisations",
      component: Organisations,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/organisations/:id/",
      name: "Organisation",
      component: Organisation,
      props: true,
      meta: { loginRequired: true, module: "admin" },
    },

    {
      path: "/auth/login/",
      name: "Login",
      component: Login,
      meta: { loginRequired: false, module: "auth" },
    },

    {
      path: "/auth/logout/",
      name: "Logout",
      component: Logout,
      meta: { loginRequired: false, module: "auth" },
    },

    {
      path: "/auth/password/reset/",
      name: "PasswordReset",
      component: PasswordReset,
      meta: { loginRequired: false, module: "auth" },
    },

    {
      path: "/auth/password/reset/confirm/:uid/:token/",
      name: "PasswordResetConfirm",
      component: PasswordResetConfirm,
      meta: { loginRequired: false, module: "auth" },
    },

    {
      path: "/permission-denied/",
      name: "PermissionDenied",
      component: PermissionDenied,
    },

    { path: "*", name: "file-not-found", component: FileNotFound },
  ],
  mode: "history",
  base: process.env.BASE_URL,
})

router.beforeEach(async (to, from, next) => {
  /*
    AUTHENTICATION GUARD

    If loginRequired is set,
      then check if we have the "user" profile in the auth store
        a) if so, then call next()
        b) if not, then check
          if we can get the "user" profile (i.e. we are actually logged in but the user reloaded the page)
            i) if so, then save details in auth store and call next()
            ii) if not, then redirect to "login" page

    If loginRequired is NOT set, then just call next()
   */
  const debug = false

  if (from.name === "Login" && from.fullPath === to.fullPath) return

  if (to.meta.loginRequired) {
    if (debug) console.debug(to.name, ": login is required")

    if (to.params.token) {
      try {
        const mapshare = await api.validateMapshareToken(to.params.token)
        if (mapshare.data.token) {
          next()
          store.set("auth/mapShare", true)
          store.set("auth/token", mapshare.data.token)
          store.set("auth/user", {
            id: null,
            organisations: [],
            permissions: [],
          })
        } else {
          alert("Token expired")
          await router.push({ name: "Login", query: { next: to.fullPath } })
        }
      } catch (e) {
        console.error(e)
      }
    } else if (await Vue.localStore.getItem("token")) {
      if (debug) console.debug(to.name, ": found auth.user, calling next()")

      store.set("auth/token", await Vue.localStore.getItem("token"))
      await setAuthToken(await Vue.localStore.getItem("token"))

      next()
    } else {
      if (debug) console.debug(to.name, ": no auth.user found, trying to get user data")

      // Get the user info
      try {
        const user = await Vue.axios.get("/users/me/")
        if (!user) throw "could not get profile"

        if (debug) console.debug(to.name, ": got user data, setting in store")

        await Vue.localStore.setItem("user", user)
        store.set("auth/user", user)
        await setAnalyticsProfile(user)

        next()
      } catch {
        await Vue.localStore.removeItem("user")
        if (debug)
          console.debug(to.name, ": couldnt get user data, redirecting to login page")
        if (to.fullPath !== "/")
          await router.push({ name: "Login", query: { next: to.fullPath } })
        else await router.push({ name: "Login" })
      }
    }
  } else {
    if (debug) console.debug(to.name, ": no login required, showing page")
    next()
  }
})

export default router
