<template>
  <div class="MapForm">
    <v-form ref="mapform" @submit.prevent="() => false">
      <v-row>
        <v-col cols="12" sm="6">
          <text-field
            v-model="map.name"
            :readonly="!edit"
            :error-messages="errors.name"
            label="Name"
            name="name"
            outlined
            dense
            required
          />
        </v-col>

        <v-col cols="12" sm="3">
          <text-field
            v-model="map.zoom_min"
            :readonly="!edit"
            :error-messages="errors.zoom_min"
            :rules="rules.zoom"
            type="number"
            label="Minimum Zoom"
            name="zoom_min"
            hint="Minimum zoom level of the layer"
            empty="0"
            persistent-hint
            outlined
            dense
            required
          />
        </v-col>

        <v-col cols="12" sm="3">
          <text-field
            v-model="map.zoom_max"
            :readonly="!edit"
            :error-messages="errors.zoom_max"
            :rules="rules.zoom"
            type="number"
            label="Maximum Zoom"
            name="zoom_max"
            hint="Maximum zoom level of the layer"
            persistent-hint
            outlined
            dense
            required
          />
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 mt-3">
          <text-field
            v-model="map.title"
            :readonly="!edit"
            :error-messages="errors.title"
            label="Title"
            name="title"
            empty="&ndash;"
            hint="Optional title to be shown at top of map"
            persistent-hint
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 mt-3">
          <text-field
            v-model="map.description"
            :readonly="!edit"
            :error-messages="errors.description"
            label="Description"
            name="description"
            empty="&ndash;"
            persistent-hint
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 mt-3">
          <text-field
            v-model="map.attribution"
            :readonly="!edit"
            :error-messages="errors.attribution"
            label="Attribution"
            name="attribution"
            empty="&ndash;"
            outlined
            dense
          />
        </v-col>

        <!--Disabled minimap until backend support minimap options as satellite or street-->
        <v-col v-if="false" cols="12" md="3" class="py-0 my-2">
          <text-field
            v-if="!edit"
            v-model="miniMapName"
            label="Minimap"
            empty="&ndash;"
            readonly
          />
          <v-select
            v-else
            v-model="map.minimap"
            :items="miniMapOptions"
            item-value="id"
            item-text="name"
            label="Minimap"
            outlined
            dense
            auto
          />
        </v-col>

        <v-col cols="12" md="3" class="py-0 my-2">
          <text-field
            v-if="!edit || !isOwner"
            v-model="visibility"
            label="Visibility"
            readonly
          />

          <v-select
            v-else
            v-model="map.visibility"
            :items="visibilityOptions"
            item-value="id"
            item-text="name"
            label="Visibility"
            outlined
            dense
            auto
          />
        </v-col>

        <v-col cols="12" md="6" class="py-0 my-2">
          <div
            v-if="!edit && controls.length > 0"
            class="mt-n1 caption grey--text text--darken-1"
          >
            Controls
          </div>
          <v-chip-group v-if="!edit && controls.length > 0" column>
            <v-chip v-for="control in controls" :key="control.id" small outlined>
              {{ control.text }}
            </v-chip>
          </v-chip-group>
          <text-field
            v-else-if="!edit && controls.length === 0"
            readonly
            label="Map Controls"
          />
          <v-select
            v-else
            id="map-controls-field"
            v-model="map.controls"
            :items="availableControls"
            item-text="text"
            item-value="id"
            label="Map Controls"
            hint="Select controls to show on map"
            class="control-selection"
            chips
            small-chips
            deletable-chips
            persistent-hint
            multiple
            outlined
            dense
            auto
          />
        </v-col>

        <v-col cols="12" md="6" class="py-0 my-2">
          <text-field
            v-if="!edit"
            v-model="filesListName"
            label="Files List"
            empty="&ndash;"
            readonly
          />
          <v-select
            v-else
            v-model="map.files_list"
            :items="filesListOptions"
            item-value="id"
            item-text="name"
            label="Files List"
            outlined
            dense
            auto
          />
        </v-col>

        <v-col cols="12" class="py-0 my-2 fake-label">
          <draw-bounding-box
            v-if="map && map.layers"
            v-model="map.bounds"
            :extent="map.extent"
            :edit="edit"
            label="Default Map View"
            selected-label="Default View"
          >
            <template #help>
              <v-tooltip max-width="400" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon class="ml-1" color="info" small v-bind="attrs" v-on="on">
                    fal fa-question-circle
                  </v-icon>
                </template>
                <span class="text-body-2">
                  <p class="text-h6">Defines the default Map view.</p>

                  <p>
                    <strong>"Data Extent"</strong> shows the combined coverage of all
                    Layers and their configured bounds. This can change if the attached
                    Layers change, for example by changing their files or bounds.
                  </p>
                  <p>
                    <strong>"Default View"</strong> defines what area the Map should
                    show if no coordinates or zoom have been submitted in the URL.
                  </p>
                  <p>
                    <u>Note:</u> the actual Map display will differ from the area of the
                    Default Map View depending on the user's browser window size.
                  </p>
                </span>
              </v-tooltip>
            </template>
          </draw-bounding-box>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DrawBoundingBox from "@/components/DrawBoundingBox"
import TextField from "@/components/TextField"
import { get } from "vuex-pathify"
import layerApi from "@/modules/layers/api"
import mapApi from "@/modules/maps/api"

export default {
  name: "MapForm",

  components: {
    DrawBoundingBox,
    TextField,
  },

  props: {
    value: { type: Object, required: true },
    edit: { type: Boolean, required: true },
  },

  data: () => ({
    map: {},
    errors: {},

    visibility: "Owner",
    visibilityOptions: [
      { id: "OWN", name: "Owner" },
      { id: "ORG", name: "Organisation-wide" },
    ],
    miniMapOptions: [
      { id: "satellite-layer", name: "Satellite Map" },
      { id: "street-layer", name: "Street Map" },
    ],
    availableControls: [
      { id: "create_section", text: "Cross Section" },
      { id: "map_share", text: "Map Share" },
      { id: "map_pdf", text: "Map to PDF" },
      { id: "map_files", text: "View/Download Map Files" },
      { id: "measure", text: "Measure Distance" },
    ],
    filesListOptions: [
      { id: null, name: "Disabled" },
      { id: true, name: "Enabled and visible" },
      { id: false, name: "Enabled but hidden" },
    ],
    rules: {
      required: [(v) => (v !== null && v !== "") || "Value is required"],
      zoom: [
        (v) => (v !== null && v !== "") || "Value is required",
        (v) => parseInt(v) < 23 || "Must be integer between 0 and 22",
        (v) => parseInt(v) >= 0 || "Must be integer between 0 and 22",
        (v) => Number.isInteger(parseFloat(v)) || "Must be integer",
      ],
    },
  }),

  computed: {
    layers: get("maps/layers"),
    ...get("auth", ["user"]),
    organisationId: get("organisationId"),

    isOwner() {
      return this.map?.owner?.id === this.user?.id
    },

    miniMapName() {
      const minimap = this.miniMapOptions.find((layer) => layer.id === this.map.minimap)
      return minimap ? minimap.name : "-"
    },

    controls() {
      return this.availableControls.filter((control) =>
        this.map.controls?.includes(control.id)
      )
    },

    filesListName() {
      return (
        this.filesListOptions.find((item) => item.id === this.map.files_list)?.name ||
        ""
      )
    },
  },

  watch: {
    "map.visibility"(value) {
      if (value === "ALL") this.visibility = "All"
      else
        this.visibility = this.visibilityOptions.find((item) => item.id === value)?.name
    },
    value(updatedMap) {
      this.map = { ...this.value } // shallow copy to detatch form data from state
    },
  },

  async created() {
    // TODO: need to get layers for minimap dropdown
    this.map = { ...this.value } // shallow copy to detatch form data from state
  },

  methods: {
    async getLayers() {
      if (!this.organisationId) return
      try {
        const response = await layerApi.getOrganisationLayers(this.organisationId)
        if (response) this.layers = response
      } catch (e) {
        console.error(e)
      }
    },

    save() {
      // TODO: validate min/max zoom
      // TODO: Handle map errors
      return this.map
    },

    cancel() {
      this.map = { ...this.value } // shallow copy to detatch form data from state
    },
  },
}
</script>

<style scoped>
.fake-label .caption {
  position: absolute;
  margin-left: 15px;
  margin-top: -10px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  z-index: 1;
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}

.fake-label >>> .maplibregl-map {
  border: 1px solid #bebebe;
  border-radius: 4px;
}

.pointer {
  cursor: pointer;
}

.draggable:hover {
  cursor: move !important;
}
</style>
