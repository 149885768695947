<template>
  <v-autocomplete
    v-if="organisations.length > 1"
    v-model="organisationId"
    v-click-outside="outside"
    :items="organisations"
    color="primary"
    item-text="name"
    item-value="id"
    label="Organisation"
    hide-details
    hide-no-data
    dense
    outlined
    @change="updateOrganisationId(organisationId)"
  />
</template>

<script>
import { get, sync, call } from "vuex-pathify"
import api from "@/modules/organisations/api"
import theme from "@/assets/theme.json"

export default {
  name: "OrganisationSelector",

  props: {
    expand: { type: Boolean, default: false },
  },

  data: () => ({
    expandLocal: null,
  }),

  computed: {
    activeMap: sync("activeMap"),
    organisationId: sync("organisationId"),
    organisations: get("auth/user@organisations"),
  },

  methods: {
    updateOrganisationId: call("updateOrganisationId"),
    outside() {
      if (!this.expand) this.expandLocal = false
    },
  },
}
</script>
