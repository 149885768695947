<template>
  <v-row v-if="user" class="fill-height" no-gutters>
    <v-col cols="12">
      <v-list class="mt-1 px-0">
        <v-list-item
          v-if="canEditMap"
          :class="routeName === 'Maps' || routeName === 'Map' ? 'grey lighten-2' : ''"
          :to="{ name: 'Maps' }"
          class="settings-menu__maps"
        >
          <v-list-item-avatar>
            <v-icon>fal fa-globe</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Maps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="canEditLayer"
          :class="
            routeName === 'Layers' || routeName === 'Layer' ? 'grey lighten-2' : ''
          "
          :to="{ name: 'Layers' }"
          class="settings-menu__layers"
        >
          <v-list-item-avatar>
            <v-icon>fal fa-layer-group</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Layers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="canEditUser"
          :class="routeName === 'Users' || routeName === 'User' ? 'grey lighten-2' : ''"
          :to="{ name: 'Users' }"
          class="settings-menu__users"
        >
          <v-list-item-avatar :to="{ name: 'Users' }">
            <v-icon>fal fa-user</v-icon>
          </v-list-item-avatar>
          <v-list-item-content :to="{ name: 'Users' }">
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="canEditOrganisation"
          :class="
            routeName === 'Organisations' || routeName === 'Organisation'
              ? 'grey lighten-2'
              : ''
          "
          :to="{ name: 'Organisations' }"
          class="settings-menu__organisations"
        >
          <v-list-item-avatar>
            <v-icon>fal fa-building</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Organisations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-spacer />

    <v-col cols="12" class="d-flex align-end justify-center mb-2">
      <div>
        <organisation-selector
          v-if="user.organisations.length > 1"
          class="mb-2"
          expand
        />

        <user-impersonate v-if="isUserAllowed('profiles.impersonate')" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { get, sync } from "vuex-pathify"

import { userHasPerm } from "@/utils/users"
import { isUserAllowed } from "@/utils/general"

import OrganisationSelector from "@/modules/organisations/OrganisationSelector"
import UserImpersonate from "@/modules/users/UserImpersonate"

/**
 * @vuese
 * @group Admin
 * Component for Admin sidebar
 */
export default {
  name: "AdminSidebar",

  components: { UserImpersonate, OrganisationSelector },

  computed: {
    user: get("auth/user"),
    drawer: sync("drawer"),
    toast: sync("toast"),

    canEditMap() {
      return this.user ? this.userHasPerm(this.user, "maps.change_map") : false
    },

    canEditLayer() {
      return this.user ? this.userHasPerm(this.user, "layers.change_layer") : false
    },

    canEditUser() {
      return this.user ? this.userHasPerm(this.user, "users.change_user") : false
    },

    canEditOrganisation() {
      return this.user
        ? this.userHasPerm(this.user, "organisations.change_organisation")
        : false
    },

    routeName() {
      return this.$route.name
    },
  },

  methods: {
    isUserAllowed,
    userHasPerm,
  },
}
</script>
