import Vue from "vue"
import localforage from "localforage"

localforage.config({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
  name: "portal",
})

Vue.localStore = localforage
Object.defineProperties(Vue.prototype, {
  localStore: {
    get() {
      return localforage
    },
  },
})

export default localforage
