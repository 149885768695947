import axios from "axios"
import api from "@/utils/axios"

const axiosInstance = axios.create({
  baseURL: "/api/",
  // Django CSRF token
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
})

axiosInstance.interceptors.request.use((request) => api.requestHandler(request))

axiosInstance.interceptors.response.use(
  (response) => api.successHandler(response),
  (error) => api.errorHandler(error)
)

export default axiosInstance
