<template>
  <div class="template-editor">
    <label :class="!edit ? 'fake-label ml-2' : 'label'"> {{ label }} </label>
    <pre v-if="edit" class="pa-3 black--text">{{ code }}</pre>
    <Editor
      v-else
      v-model="code"
      :highlight="highlight"
      :line-numbers="lineNumbers"
      :readonly="readonly"
      class="my-editor mb-0"
      language="html"
    />

    <div
      v-if="persistentHint"
      class="v-text-field__details ml-2 mt-2"
      style="position: absolute"
    >
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ hint }}
          </div>
        </div>
      </div>
    </div>

    <v-layout align-end justify-end class="mt-2 mr-5">
      <v-menu v-if="!edit" offset-y>
        <template #activator="{ on, attrs }">
          <v-btn color="primary" outlined x-small v-bind="attrs" v-on="on">
            Apply popup template
            <v-icon class="ml-1" x-small>fal fa-angle-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in templates"
            :key="index"
            @click="applyTemplate(item.code)"
          >
            <v-list-item-title> {{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-layout>
    <popup-code-document />
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor"
import { highlight, languages } from "prismjs/components/prism-core"

import "prismjs/components/prism-clike"
import "prismjs/components/prism-markup"

import "vue-prism-editor/dist/prismeditor.min.css"
import "@/stylus/editorstyles.css"

import standardPopup from "@/modules/layers/templates/Standard-popup.json"
import ImageViewerPopup from "@/modules/layers/templates/Image-viewer-popup.json"
import TabViewPopup from "@/modules/layers/templates/Tab-view-popup.json"
import PotreePopup from "@/modules/layers/templates/Potree-popup.json"
import SimplePopup from "@/modules/layers/templates/Simple-popup.json"
import PanoramaPhotos from "@/modules/layers/templates/panorama-popup.json"
import PopupCodeDocument from "@/components/PopupCodeDocument"

export default {
  name: "VueTemplateEditor",

  components: {
    Editor: PrismEditor,
    PopupCodeDocument,
  },

  props: {
    value: { type: String, default: "" },
    edit: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    label: { type: String, default: "" },
    hint: { type: String, default: "" },
  },

  data: () => ({
    lineNumbers: true,
    readonly: false,
    code: "",
    templates: [],
  }),

  watch: {
    value(newVal) {
      this.code = newVal
    },
    code(value) {
      this.$emit("input", value)
    },
    edit(value) {
      this.readonly = value
    },
  },

  created() {
    this.code = this.value
    this.readonly = this.edit
    this.templates = [
      { name: standardPopup.name, code: standardPopup.code },
      { name: ImageViewerPopup.name, code: ImageViewerPopup.code },
      { name: PotreePopup.name, code: PotreePopup.code },
      { name: SimplePopup.name, code: SimplePopup.code },
      { name: TabViewPopup.name, code: TabViewPopup.code },
      { name: PanoramaPhotos.name, code: PanoramaPhotos.code },
    ]
  },

  methods: {
    highlight(code) {
      return highlight(
        code,
        {
          ...languages["markup"],
        },
        "markup"
      )
    },

    applyTemplate(popup) {
      this.$emit("input", popup)
    },

    htmlDecode(input) {
      let e = document.createElement("div")
      e.innerHTML = input
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue
    },
  },
}
</script>

<style scoped>
.prism-editor__textarea:focus {
  outline: none;
}

.my-editor {
  background-color: #fafafa;
  max-height: 400px;
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 18px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.36) !important;
}

.fake-label {
  position: absolute;
  margin-left: 15px;
  margin-top: -10px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  z-index: 1;
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}

.label {
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}

pre {
  background-color: #f5f5f5 !important;
  border-radius: 6px;
}
</style>
