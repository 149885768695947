var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot",staticStyle:{"width":"100%"}},[(_vm.label)?_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"position":"absolute"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:{
          outlined: _vm.outlined,
          standard: !_vm.outlined,
          'grey lighten-4 multi-line': _vm.multiLine,
        },staticStyle:{"overflow-y":"auto","max-height":"400px","width":"100%"}},[(
            (_vm.value && typeof _vm.value === 'string' && _vm.value.trim().length > 0) ||
            typeof _vm.value !== 'string'
          )?_c('span',[_vm._t("default")],2):(_vm.empty)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.empty)}}):_c('span',[_vm._t("default")],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }