<template>
  <v-form ref="form" @submit.prevent="() => false">
    <v-row>
      <!-- NAME -->
      <v-col cols="12" md="6" class="py-0 my-2">
        <text-field
          v-model="layer.name"
          :readonly="!editable"
          :error-messages="errors.name"
          label="Name"
          name="name"
          outlined
          dense
          required
        />
      </v-col>

      <!-- VISIBILITY -->
      <v-col cols="12" md="6" class="py-0 my-2">
        <text-field
          v-if="!edit || !isOwner"
          v-model="visibilityName"
          label="Visibility"
          readonly
        />

        <v-select
          v-else
          v-model="layer.visibility"
          :items="visibilityOptions"
          item-value="id"
          item-text="name"
          label="Visibility"
          outlined
          dense
          auto
        />
      </v-col>

      <!-- DESCRIPTION -->
      <v-col cols="12" class="py-0 my-2">
        <text-field
          v-model="layer.description"
          :readonly="!editable"
          :error-messages="errors.description"
          label="Description"
          name="description"
          empty="&ndash;"
          multi-line
          outlined
          dense
        />
      </v-col>

      <!-- ATTRIBUTION -->
      <v-col cols="12" md="6" class="py-0 my-2">
        <text-field
          v-model="layer.attribution"
          :readonly="!editable"
          :error-messages="errors.attribution"
          label="Attribution"
          name="attribution"
          empty="&ndash;"
          outlined
          dense
        />
      </v-col>

      <!-- FILE LIST VISIBILITY -->
      <v-col cols="12" md="6" class="py-0 my-2">
        <text-field
          v-if="!edit"
          v-model="fileListVisibilityName"
          label="File List Visibility"
          readonly
        />

        <v-select
          v-else
          v-model="layer.file_list_visibility"
          :items="fileListVisibilityOptions"
          item-value="id"
          item-text="name"
          label="File List Visibility"
          outlined
          dense
          auto
        />
      </v-col>

      <!-- ATTACHMENT FIELD -->
      <v-col
        v-if="layer.type !== 'raster' && layer.id"
        cols="12"
        md="6"
        class="py-0 my-2"
      >
        <text-field
          v-if="!edit || (layer.attributes && layer.attributes.length === 0)"
          v-model="layer.attachment_field"
          label="Attachment field"
          readonly
        />
        <v-select
          v-else
          id="attachment-field"
          v-model="layer.attachment_field"
          :items="layer.attributes"
          label="Attachment field"
          outlined
          dense
          auto
        />
      </v-col>

      <!-- SEARCH FIELD -->
      <v-col
        v-if="layer.type !== 'raster' && layer.id"
        cols="12"
        md="6"
        class="py-0 my-2"
      >
        <text-field
          v-if="!edit || (layer.attributes && layer.attributes.length === 0)"
          v-model="layer.search_field"
          label="Search field"
          readonly
        />
        <v-select
          v-else
          id="search-field"
          v-model="layer.search_field"
          :items="layer.attributes"
          label="Search field"
          outlined
          dense
          auto
        />
      </v-col>

      <!-- ENABLED -->
      <v-col v-if="false" cols="12" md="3" class="py-0">
        <v-switch v-model="layer.enabled" :readonly="!editable" label="Enabled" />
      </v-col>

      <v-col cols="12" class="py-0 mb-7">
        <v-btn
          class="ma-0"
          color="primary"
          block
          small
          outlined
          @click="expand = !expand"
        >
          {{ expand ? "Hide advanced fields" : "Show Advanced fields" }}
        </v-btn>
      </v-col>

      <v-expand-transition>
        <v-flex v-show="expand" class="mx-0">
          <v-row class="px-3">
            <!-- SUB LAYERS -->
            <v-col v-if="layer.id" cols="12" md="12">
              <label
                v-if="!editable && layer.sub_layers && layer.sub_layers.length > 0"
                class="label-text"
              >
                Sub layers
              </label>
              <v-chip-group
                v-if="!editable && layer.sub_layers && layer.sub_layers.length > 0"
                column
              >
                <v-chip
                  v-for="subLayer in layer.sub_layers"
                  :key="subLayer"
                  small
                  outlined
                >
                  {{ subLayer }}
                </v-chip>
              </v-chip-group>

              <text-field
                v-else-if="!editable"
                v-model="layer.sub_layers"
                label="Sub layers"
                readonly
              />

              <select-all-inverse
                v-else-if="subLayerOptions"
                v-model="layer.sub_layers"
                :items="subLayerOptions"
                item-value="id"
                item-text="text"
                label="Filter By Sub-layers"
                autocomplete
                clearable
                dense
                hide-details
                multiple
                outlined
                sticky-header
              />
            </v-col>

            <!-- ZOOM MIN -->
            <v-col cols="12" md="6" class="py-0 my-2">
              <text-field
                v-model="layer.zoom_min"
                :readonly="!editable"
                :rules="rules.zoom"
                empty="0"
                type="number"
                label="Minimum Zoom"
                name="zoom_min"
                hint="Minimum zoom level of the layer"
                persistent-hint
                outlined
                dense
                required
              />
            </v-col>

            <!-- ZOOM MAX -->
            <v-col cols="12" md="6" class="py-0 my-2">
              <text-field
                v-model="layer.zoom_max"
                :readonly="!editable"
                :rules="rules.zoom"
                type="number"
                label="Maximum Zoom"
                name="zoom_max"
                hint="Maximum zoom level of the layer"
                persistent-hint
                outlined
                dense
                required
              />
            </v-col>

            <!-- STYLE -->
            <v-col
              v-if="layer.type !== 'raster'"
              cols="12"
              lg="6"
              class="py-0 mb-7 my-2"
            >
              <json-editor
                v-model="layer.style"
                :edit="!editable"
                label="Style"
                hint="JSON value to style layer"
                persistent-hint
              />
            </v-col>

            <!-- LABEL -->
            <v-col
              v-if="layer.type !== 'raster'"
              cols="12"
              lg="6"
              class="py-0 mb-7 my-2"
            >
              <json-editor
                v-model="layer.label"
                :edit="!editable"
                label="Label"
                hint="JSON value to style layer label"
                persistent-hint
              />
            </v-col>
          </v-row>

          <!-- POPUP -->
          <v-col v-if="layer.type !== 'raster'" cols="12" class="py-0 mb-8 mt-10">
            <vue-template-editor
              v-model="layer.popup"
              :edit="!editable"
              label="Vue popup Template"
              hint="Vue template code for popup display"
              persistent-hint
            />
          </v-col>
        </v-flex>
      </v-expand-transition>

      <v-col v-if="layer.id" cols="12" md="12" class="py-0 my-2 fake-label">
        <!-- BOUNDS -->
        <draw-bounding-box
          :key="key"
          v-model="layer.bounds"
          :extent="layer.extent"
          :edit="edit"
          label="Layer Bounds"
        >
          <template #help>
            <v-tooltip max-width="400" bottom>
              <template #activator="{ on, attrs }">
                <v-icon class="ml-1" color="info" small v-bind="attrs" v-on="on">
                  fal fa-question-circle
                </v-icon>
              </template>
              <span class="text-body-2">
                <p class="text-h6">
                  Defines what area of the uploaded geospatial data to show.
                </p>

                <p class="body-2">
                  <strong>"Data Extent"</strong> shows the data coverage of the uploaded
                  geospatial data.
                </p>
                <p>
                  <strong>"Visible Data"</strong> defines which part of the data
                  coverage should be shown on the Map. Data outside this box will not be
                  shown. As a default, all data of the uploaded geospatial files will be
                  shown.
                </p>
                <p>
                  This setting is particularly useful, if the uploaded data have
                  non-relevant parts, for example if an AutoCAD file included some
                  borders.
                </p>
              </span>
            </v-tooltip>
          </template>
        </draw-bounding-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { get } from "vuex-pathify"

import JsonEditor from "@/components/JsonEditor"
import VueTemplateEditor from "@/components/VueTemplateEditor"
import TextField from "@/components/TextField"
import DrawBoundingBox from "@/components/DrawBoundingBox"
import SelectAllInverse from "@/components/SelectAllInverse"

export default {
  name: "LayerForm",

  components: {
    SelectAllInverse,
    DrawBoundingBox,
    TextField,
    JsonEditor,
    VueTemplateEditor,
  },

  props: {
    value: { type: Object, required: true },
    edit: { type: Boolean, default: false },
    formErrors: { type: Array, default: () => [] },
  },

  data: () => ({
    expand: false,
    editable: false,
    saving: false,
    key: Math.floor(Math.random() * 4),
    search: "",
    layer: {},
    attachmentField: [],
    errors: {},
    oldValues: null,

    visibilityOptions: [
      { id: "OWN", name: "Owner" },
      { id: "ORG", name: "Organisation-wide" },
    ],

    fileListVisibilityOptions: [
      { id: "ALL", name: "All" },
      { id: "GEOSPATIAL", name: "Geospatial files only" },
      { id: "ATTACHMENT", name: "Attachment files only" },
      { id: "NONE", name: "Don't show any files in the files side bar" },
    ],

    rules: {
      required: [(v) => !!v || "Value is required"],
      zoom: [
        (v) => parseInt(v) < 23 || "Must be integer between 0 and 22",
        (v) => parseInt(v) >= 0 || "Must be integer between 0 and 22",
        (v) => Number.isInteger(parseInt(v)) || "Must be integer",
      ],
    },
  }),

  computed: {
    ...get("auth", ["user"]),

    isOwner() {
      return !this.layer.owner || this.layer.owner.id === this.user.id
    },

    fileListVisibilityName() {
      return this.fileListVisibilityOptions.find(
        (item) => item.id === this.layer.file_list_visibility
      )?.name
    },

    visibilityName() {
      return this.visibilityOptions.find((item) => item.id === this.layer.visibility)
        ?.name
    },

    subLayerOptions() {
      if (!this.layer || this.layer.available_sub_layers?.length === 0) return []

      return this.layer.available_sub_layers.map((subLayer) => ({
        id: subLayer,
        text: subLayer,
      }))
    },
  },

  watch: {
    formErrors(value) {
      if (value) this.errors = value
    },

    edit: {
      handler(value) {
        this.editable = value
      },
      immediate: true,
    },

    value: {
      handler(value) {
        this.layer = value

        if (!this.layer.bounds) this.layer.bounds = this.layer.extent

        if (!this.layer.file_list_visibility)
          this.layer.file_list_visibility = this.fileListVisibilityOptions[0].id

        if (!this.layer.visibility) this.layer.visibility = this.visibilityOptions[0].id

        this.$nextTick(() => {
          this.key = Math.floor(Math.random() * 4)
        })
      },
      immediate: true,
    },

    "layer.sub_layers"() {
      this.search = ""
    },
  },
}
</script>

<style scoped>
.fake-label .caption {
  position: absolute;
  margin-left: 15px;
  margin-top: -10px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  z-index: 1;
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}
.label-text {
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}
.fake-label >>> .maplibregl-map {
  border: 1px solid #bebebe;
  border-radius: 4px;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 1;
}
</style>
