<template>
  <v-dialog :value="value" max-width="500px">
    <v-card>
      <v-card-title class="primary white--text"> Change Password </v-card-title>

      <v-card-text class="pt-4 pb-0 mb-0">
        <v-form id="change-password-form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="user.old_password"
                  :error-messages="errors.old_password"
                  :rules="rules.required"
                  :type="showPasswordOld ? 'text' : 'password'"
                  color="primary"
                  label="Current Password"
                  name="old_password"
                  dense
                  outlined
                >
                  <template #append>
                    <v-btn
                      icon
                      small
                      tabindex="-1"
                      @click="showPasswordOld = !showPasswordOld"
                    >
                      <v-icon v-if="showPasswordOld" small>fal fa-eye</v-icon>
                      <v-icon v-else small>fal fa-eye-slash</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="user.new_password1"
                  :error-messages="errors.new_password1"
                  :rules="rules.password"
                  :type="showPassword1 ? 'text' : 'password'"
                  color="primary"
                  label="New Password"
                  name="new_password1"
                  dense
                  outlined
                >
                  <template #append>
                    <v-btn
                      icon
                      small
                      tabindex="-1"
                      @click="showPassword1 = !showPassword1"
                    >
                      <v-icon v-if="showPassword1" small>fal fa-eye</v-icon>
                      <v-icon v-else small>fal fa-eye-slash</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="user.new_password2"
                  :error-messages="errors.new_password2"
                  :rules="rules.confirm"
                  :type="showPassword2 ? 'text' : 'password'"
                  color="primary"
                  label="Confirm Password"
                  name="new_password2"
                  dense
                  outlined
                >
                  <template #append>
                    <v-btn
                      icon
                      small
                      tabindex="-1"
                      @click="showPassword2 = !showPassword2"
                    >
                      <v-icon v-if="showPassword2" small>fal fa-eye</v-icon>
                      <v-icon v-else small>fal fa-eye-slash</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn color="grey" small text @click="$emit('input', false)"> Cancel </v-btn>
        <v-spacer />
        <v-btn id="save-btn" color="primary" small @click="save()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, get } from "vuex-pathify"

import api from "@/modules/auth/api"

export default {
  name: "UserPassword",

  props: {
    value: { type: Boolean, default: null },
  },

  data() {
    return {
      showPassword2: false,
      showPassword1: false,
      showPasswordOld: false,

      user: {},
      errors: {},
      rules: {
        required: [(v) => !!v || "Value is required"],
        old_password: [
          (v) => !!v || "Value is required",
          (v) => (v && v.length > 7) || "Password should be at lease 8 characters long",
        ],
        confirm: [(v) => this.user.new_password1 === v || "Password should match"],
      },
    }
  },

  computed: {
    currentUser: get("auth/user"),
    toast: sync("toast"),
  },

  watch: {
    value: {
      handler() {
        this.user = {
          ...this.user,
          old_password: null,
          new_password1: null,
          new_password2: null,
        }
      },
      immediate: true,
    },
  },

  methods: {
    async save() {
      if (
        this.user.old_password &&
        this.user.new_password1 === this.user.new_password2
      ) {
        try {
          const resp = await api.changePassword(
            this.user.old_password,
            this.user.new_password1,
            this.user.new_password2
          )
          if (resp.status === 200) {
            this.showToast("success", resp.data.detail)
            this.$emit("input", false)
          } else {
            this.errors = resp.data
            this.showToast("error", resp.data.detail)
          }
        } catch (e) {
          console.error(e)
        }
      }
    },

    showToast(color = "success", message) {
      this.toast.show = true
      this.toast.color = color
      this.toast.text = message
    },
  },
}
</script>

<style scoped>
.v-text-field--outlined >>> .v-input__append-inner {
  height: 40px;
  margin-top: 0 !important;
  align-items: center;
}
</style>
