import Vue from "vue"

export default {
  getUsers(organisationId) {
    return Vue.axios
      .get(`/organisations/${organisationId}/users/`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  getUserDetails(userId) {
    return Vue.axios
      .get(`/users/${userId}/`)
      .then((response) => response)
      .catch((error) => error.response)
  },

  editUser(user) {
    return Vue.axios
      .patch(`/users/${user.id}/`, user)
      .then((response) => response)
      .catch((error) => error.response)
  },

  deleteUser(user) {
    return Vue.axios
      .delete(`/users/${user.id}/`, user)
      .then((response) => response)
      .catch((error) => error.response)
  },

  createUser(user) {
    return Vue.axios
      .post("/users/", user)
      .then((response) => response)
      .catch((error) => error.response)
  },
}
