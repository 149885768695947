<template>
  <div>
    <div class="caption d-flex">
      {{ label }}
      <slot name="help"></slot>
    </div>
    <div id="map-bounds" style="height: 400px" />
    <h4 v-if="zoomError" class="zoom-warning">{{ displayText }}</h4>

    <map-draw-rectangle
      v-if="!loading"
      v-model="bounds"
      :maplibre="maplibre"
      :edit="edit"
      :selected-label="selectedLabel"
    />
  </div>
</template>

<script>
import maplibregl from "maplibre-gl"
import MapDrawRectangle from "@/components/MapDrawRectangle"
import turf from "turf"
// import MapDeleteRectangle from "@/components/MapDeleteRectangle"

export default {
  name: "DrawBoundingBox",

  components: { MapDrawRectangle },

  props: {
    value: { type: Array, default: () => [] },
    extent: { type: Array, default: () => [] },
    edit: { type: Boolean, default: false },
    label: { type: String, default: "" },
    selectedLabel: { type: String, default: "Visible data" },
  },

  data: () => ({
    maplibre: null,
    bounds: null,
    loading: true,
    zoomError: false,
  }),

  computed: {
    displayText() {
      if (
        /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return "Use two fingers to move the map"
      } else return "Use Ctrl + Scroll to zoom"
    },
  },

  watch: {
    bounds(value, oldValue) {
      if (oldValue && value?.length > 0) {
        this.redrawBounds(value)
      }
      this.$emit("input", value)
    },
    edit(value) {
      if (this.value?.length > 0) this.redrawBounds(this.value)
      if (this.maplibre?.getLayer("temp_bbox")) {
        if (value) this.maplibre.setLayoutProperty("temp_bbox", "visibility", "none")
        else this.maplibre.setLayoutProperty("temp_bbox", "visibility", "visible")
      }
    },
  },

  created() {
    this.bounds = this.value || []
  },
  mounted() {
    this.$nextTick(() => {
      this.createMap()
    })
  },

  methods: {
    createMap() {
      let host = `${window.location.protocol}//${window.location.host}`

      const glyphsUrl =
        "https://mapsutd-portal-fonts.storage.googleapis.com/{fontstack}/{range}.pbf"
      const spriteUrl = `${host}/static/img/sprites/maki`
      this.maplibre = new maplibregl.Map({
        container: "map-bounds",
        hash: false, // Zoom and center in URL hash
        attributionControl: false,
        preserveDrawingBuffer: true,
        bounds: this.bounds?.length > 0 ? this.bounds : null,
        style: {
          version: 8,
          sources: {},
          layers: [
            {
              id: "background",
              type: "background",
              paint: { "background-color": "grey" },
            },
          ],
          glyphs: glyphsUrl,
          sprite: spriteUrl,
        },
        zoom: 8,
      })

      this.maplibre.on("load", () => {
        this.$nextTick(() => {
          let source = {
            type: "raster",
            tiles: [
              "https://maps.omniscale.net/v2/irishrail2-40bb44c9/style.default/{z}/{x}/{y}.png",
            ],
            minzoom: 0,
            maxzoom: 22,
            attribution: "",
            tileSize: 256,
          }
          this.maplibre.addSource("background", source)

          this.maplibre.addLayer({
            id: "background-layer",
            type: "raster",
            source: "background",
            minzoom: 0,
            maxzoom: 22,
          })
          this.maplibre.resize()
          this.loading = false

          if (this.extent?.length > 0) {
            // this.maplibre.fitBounds(this.extent, { animate: false, padding: 20 })
            let pgn = turf.bboxPolygon(this.extent) //convert it to Polygon feature
            this.generateLabel(
              this.extent[1] > this.extent[3] ? this.extent[1] : this.extent[3],
              this.extent[0] < this.extent[2] ? this.extent[0] : this.extent[2],
              "ext",
              "Data extent"
            )
            this.generatePolygon(pgn, "extent", "#fbb03b")
          }
          if (this.bounds?.length > 0) {
            // this.maplibre.fitBounds(this.bounds, { animate: false, padding: 20 })
            let pgn = turf.bboxPolygon(this.bounds) //convert it to Polygon feature
            this.generateLabel(
              this.bounds[1] > this.bounds[3] ? this.bounds[1] : this.bounds[3],
              this.bounds[0] < this.bounds[2] ? this.bounds[0] : this.bounds[2],
              "label",
              this.selectedLabel
            )
            this.generatePolygon(pgn, "temp_bbox", "#000")
          }

          this.zoomToBound(this.extent, this.bounds)

          this.maplibre.on("wheel", (event) => {
            if (event.originalEvent.ctrlKey || event.originalEvent.altKey) {
              this.zoomError = false
              return
            }
            this.maplibre.setPaintProperty("background-layer", "raster-opacity", 0.5)

            this.zoomError = true
            event.preventDefault()
          })
          this.maplibre.on("mousemove", (event) => {
            this.maplibre.setPaintProperty("background-layer", "raster-opacity", 1)
            this.zoomError = false
          })
        })
      })
    },

    zoomToBound(extent, bound) {
      let bounds = []
      if (extent?.length > 0 && bound?.length > 0) {
        bounds = [
          Math.min(extent[0], bound[0]),
          Math.min(extent[1], bound[1]),
          Math.max(extent[2], bound[2]),
          Math.max(extent[3], bound[3]),
        ]
      } else if (extent?.length > 0) bounds = extent
      else if (bound?.length > 0) bounds = bound
      if (bounds.length > 0)
        this.maplibre.fitBounds(bounds, {
          animate: false,
          padding: { top: 100, bottom: 100, left: 100, right: 100 },
          maxZoom: 18,
        })
    },

    async generatePolygon(poly_json, name, color) {
      if (this.maplibre?.getLayer("temp_bbox")) {
        this.maplibre.removeLayer("temp_bbox")
        this.maplibre.removeSource("temp_bbox")
      }
      this.maplibre.addLayer({
        id: name,
        type: "fill",
        source: {
          type: "geojson",
          data: poly_json,
        },
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": color,
          "fill-opacity": 0.5,
        },
      })
    },

    async generateLabel(lat, lng, source, text) {
      if (this.maplibre?.getLayer(`${source}-id`)) {
        this.maplibre.removeLayer(`${source}-id`)
        this.maplibre.removeSource(source)
      }

      let label = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              label: text,
            },
            geometry: {
              type: "Point",
              coordinates: [lng, lat],
            },
          },
        ],
      }

      this.maplibre.addSource(source, {
        type: "geojson",
        data: label,
      })

      this.maplibre.addLayer({
        id: `${source}-id`,
        type: "symbol",
        source: source,
        layout: {
          "text-size": 14,
          "text-font": ["Roboto Regular"],
          "text-field": ["get", "label"],
          "text-anchor": "top",
          "text-offset": [3, -1],
        },
        paint: {
          "text-halo-color": "white",
          "text-halo-width": 0.2,
        },
      })
    },

    redrawBounds(bounds) {
      let pgn = turf.bboxPolygon(bounds) //convert it to Polygon feature
      this.generateLabel(
        this.bounds[1] > this.bounds[3] ? this.bounds[1] : this.bounds[3],
        this.bounds[0] < this.bounds[2] ? this.bounds[0] : this.bounds[2],
        "label",
        this.selectedLabel
      )
      this.generatePolygon(pgn, "temp_bbox", "#000")
    },

    unsetBounds() {
      this.bounds = []
    },
  },
}
</script>

<style>
@import "~maplibre-gl/dist/maplibre-gl.css";
#map-bounds {
  width: 100% !important;
}
.zoom-warning {
  height: 100px;
  width: auto;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  margin-top: -200px;
  z-index: 3;
}
</style>
