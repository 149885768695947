import Vue from "vue"

import router from "@/router"
import store from "../store"

export default {
  requestHandler(request) {
    return request
  },

  errorHandler(error) {
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.detail &&
      error?.response?.data?.detail === "Invalid token."
    ) {
      this.invalidTokenHandler()
      return false
    } else if (
      error?.response?.status === 403 &&
      error?.response?.config?.url === "/users/me/"
    ) {
      error.response.statusText = "Invalid authentication token"
      return false
    } else if (error?.response?.status === 400) {
      console.error(error.response)
    } else if ([404, 500].includes(error?.response?.status)) {
      error.response.statusText = "Could not connect to server"
    } else {
      return Promise.reject({ ...error })
    }
    this.showError(error.response.statusText, error.response.status)
    return Promise.reject({ ...error })
  },

  successHandler(response) {
    this.hideAlert()
    return response
  },

  showError(message, code) {
    store.set("alert", {
      show: true,
      dismissible: true,
      type: "error",
      text: code + " " + message,
    })
  },

  showSuccess(message) {
    store.set("alert", {
      show: true,
      dismissible: true,
      type: "success",
      text: message,
    })
  },

  hideAlert() {
    store.set("alert@show", false)
  },

  async invalidTokenHandler() {
    await Vue.localStore.removeItem("token")
    await router.push({ name: "Login" }, () => {})
  },

  getLayout(orgId) {
    if (orgId === null || orgId == undefined) orgId = ""
    return Vue.axios
      .get(`/layout/${orgId}`)
      .then((response) => response)
      .catch((error) => error.response)
  },
}
