<template>
  <v-container fill-height fluid class="pa-0">
    <h1 v-if="!loading && !activeMap" id="no-org" class="text-center">No map found</h1>
  </v-container>
</template>

<script>
import { get, sync } from "vuex-pathify"
export default {
  name: "Home",

  computed: {
    activeMap: get("activeMap"),
    loading: sync("loading"),
  },

  watch: {
    activeMap: {
      async handler(value) {
        if (!value) return
        this.loading = false
        await this.$router.push({ name: "MapView", params: { id: this.activeMap.id } })
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.text-center {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
