<template>
  <component
    :is="autocomplete ? 'v-autocomplete' : 'v-select'"
    :search-input.sync="searchInput"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #selection="{ index, parent }">
      <span v-if="index === 0" class="text-no-wrap">
        <small>{{ parent.value.length }} selected</small>
      </span>
      <!--<span v-if="parent.value.length < 2">{{ parent.value.length }} selected</span>
      <span v-else-if="index === 1">{{ parent.value.length }} selected</span>-->
    </template>

    <template #prepend-item>
      <v-list-item
        :class="stickyHeader ? 'sticky-header' : ''"
        class="mt-n2 grey lighten-3 min-width"
        dense
      >
        <v-row no-gutters>
          <v-col cols="6" class="py-0 ml-n2 text-center">
            <v-btn color="primary" x-small @click="toggleAll">
              {{ allOrClear }}
            </v-btn>
          </v-col>

          <v-col cols="6" class="py-0 text-center">
            <v-btn color="primary" x-small @click="inverse"> Inverse </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </template>

    <template #item="data">
      <div>
        {{ data.item[localItemText] }}
      </div>

      <v-spacer />

      <v-chip
        v-if="localItemChipText && data.item[localItemChipText]"
        class="ml-1"
        small
        label
      >
        <span class="text-truncate">
          {{ data.item[localItemChipText] }}
        </span>
      </v-chip>
    </template>
  </component>
</template>

<script>
export default {
  name: "SelectAllInverse",

  inheritAttrs: false,

  props: {
    stickyHeader: { type: Boolean, default: false },
    autocomplete: { type: Boolean, default: false },
  },

  data: () => ({
    searchInput: "",
  }),

  computed: {
    allOrClear() {
      if (this.$attrs.value?.length === this.$attrs.items?.length) return "Clear"
      else return "All"
    },

    localItemText() {
      return this.$attrs["item-text"] || "text"
    },

    localItemChipText() {
      return this.$attrs["item-chip-text"] || ""
    },
  },

  methods: {
    toggleAll() {
      if (this.$attrs.value?.length === this.$attrs.items.length) {
        this.$emit("input", [])
        this.$emit("change", [])
      } else {
        const newList = this.$attrs.items.map((item) => item.id)
        this.$emit("input", newList)
        this.$emit("change", newList)
      }
    },

    inverse() {
      // This function takes an array of IDs and an array of objects (id, value). It
      // then returns the IDs of all items that were not in the initial array,
      // i.e. it inverses the selection.
      //
      // 1. filter the options to check if the id has been currently selected and if
      //    not then keep in new array
      // 2. change array of objects to array of IDs.
      const newList = this.$attrs.items
        .filter((item) => !this.$attrs.value?.includes(item.id))
        .map((item) => item.id)
      this.$emit("input", newList)
      this.$emit("change", newList)
    },
  },
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.sticky-header {
  top: 0;
  position: sticky;
  z-index: 9000;
}

/*noinspection CssUnusedSymbol*/
.min-width {
  min-width: 200px !important;
}
</style>
