<template>
  <v-snackbar
    v-model="toast.show"
    :bottom="toast.position === 'bottom'"
    :color="toast.color"
    :left="toast.align === 'middle'"
    :multi-line="toast.mode === 'multi-line'"
    :right="toast.align === 'middle'"
    :timeout="toast.timeout"
    :top="toast.position === 'bottom'"
    :vertical="toast.mode === 'vertical'"
  >
    {{ toast.text }}
    <template #action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="toast.show = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Toast",

  computed: {
    toast() {
      return this.$store.state.toast
    },
  },
}
</script>
